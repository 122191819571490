<template>
  <div class="modal">
    <div class="modal__content modal__content--header">
      <div class="modal__close" @click="close">&times;</div>
      <h2 class="edit-view-text">Teilprojekt</h2>
      <div class="edit-input-wrapper">
        <input class="edit-input-modal" type="text" v-model="newContent" />
      </div>
      <div class="button-flex-end">
        <div class="btn-modal__black" @click="submitData">Speichern</div>
      </div>
    </div>
  </div>
</template>

<script>
import modalKeyMixin from '@/mixins/modalKeyMixin';

export default {
  mixins: [modalKeyMixin],

  data() {
    return {
      newContent: ''
    }
  },

  created() {
    this.newContent = this.$route.params.value;
  },
  
  methods: {
    submitData() {
      const newValue = this.newContent;
      const id = this.$route.params.id;
      const type = this.$route.params.type;
      const field = 'name';
      const action = 'update';
      this.$store.dispatch(`socket/updateBoard`, { type, newValue, field, action, boardId: id });
      this.close();
    },

    close() {
      this.$router.back();
    }
  }
};
</script>
