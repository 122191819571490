<template>
  <section class="login">
    <form @submit.prevent="submitForm" class="form">
      <h1 class="heading-secondary heading-secondary__margin-bottom">Login</h1>
      <div class="form__group">
        <input
          type="email"
          id="email"
          class="form__input"
          required="required"
          v-model.trim="form.email"
        />
        <label for="email" class="form__label">Email</label>
      </div>
      <div class="form__group">
        <input
          type="password"
          id="password"
          class="form__input"
          required="required"
          v-model="form.password"
        />
        <label for="password" class="form__label">Password</label>
      </div>

      <div class="button">
        <router-link to="/forgot" class="info-text info-text__link">Passwort vergessen</router-link>
        <button class="btn-base">Go &rarr;</button>
      </div>
    </form>
  </section>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    };
  },

  methods: {
    submitForm() {
      this.$store
        .dispatch('auth/login', this.form)
        .then(() => this.$router.replace({ name: 'projects' }))
        .catch(() => {
          // fire toast:
          this.$toast.error('Password vergessen? Hier klicken.', {
            position: 'bottom-center',
            hideProgressBar: true,
            closeButton: false,
            onClick: () => {
              this.$router.push({ name: 'forgot' });
            }
          });
        });
    }
  }
};
</script>
