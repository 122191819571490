<template>
  <div>
    <vc-donut
      foreground="#8a5c71"
      :size="14"
      unit="rem"
      :thickness="25"
      :sections="sections"
      :total="100"
      :start-angle="0"
      :auto-adjust-text-size="true"
    >
      <h1>{{ ist }}/{{ soll }}</h1>
      <h3>Meilensteine</h3>
    </vc-donut>
  </div>
</template>

<script>
export default {
  props: {
    ist: {
      type: Number,
      default: 0
    },
    soll: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      sections: [{ value: (this.ist / this.soll) * 100, color: '#000' }]
    };
  }
};
</script>
