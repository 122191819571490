<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    disrDataset: {
      type: Array,
      required: true
    },
    tradesList: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      chartData: {
        labels: this.tradesList,
        datasets: this.disrDataset
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: '#ffff',
            font: {
              family: 'Barlow'
            }
          }
        },
        title: {
          display: true,
          fontSize: 20,
          padding: 20,
          text: 'Störungen nach Gewerk',
          fontFamily: 'Barlow'
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 0,
            bottom: 10
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                fontFamily: 'Barlow'
              }
            }
          ],
          yAxes: [
            {
              stacked: true,
              position: 'left',
              ticks: {
                beginAtZero: true,
                fontFamily: 'Barlow'
              }
            }
          ]
        }
      }
    };
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>
