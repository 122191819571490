<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    kwLast: {
      type: Array,
      required: true
    },
    erledigt: {
      type: Array,
      required: true
    },
    nichtErledigt: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartData: {
        labels: this.kwLast,
        datasets: [
          {
            label: 'Erledigt',
            yAxisID: 'count',
            backgroundColor: '#000000',
            data: this.erledigt
          },
          {
            label: 'Nicht erledigt',
            yAxisID: 'count',
            backgroundColor: '#8a5c71',
            data: this.nichtErledigt
          }
        ]
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: '#ffff',
            font: {
              family: 'Barlow'
            },
            formatter: (value) => {
              return value == 0 ? '' : value;
            }
          }
        },
        title: {
          display: true,
          fontSize: 20,
          padding: 30,
          text: 'AEZ letzte Woche',
          fontFamily: 'Barlow'
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 0,
            bottom: 10
          }
        },
        legend: {
          position: 'bottom',
          fontFamily: 'Barlow'
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                fontFamily: 'Barlow'
              }
            }
          ],
          yAxes: [
            {
              id: 'count',
              stacked: true,
              position: 'left',
              ticks: {
                beginAtZero: true,
                fontFamily: 'Barlow'
              }
            }
          ]
        }
      }
    };
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>
