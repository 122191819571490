/** this module is used before socket id  */
import axios from 'axios';

export default {
  namespaced: true,

  state() {
    return {
      projects: [],
      selectedProjectId: ''
    };
  },

  actions: {
    async fetchAllProjects(context) {
      const response = await axios.get('/projects/');
      const projects = await response.data;
      await context.commit('FETCH_PROJECTS', projects);
    },

    async addProject(context, projectData) {
      const response = await axios.post('/projects/new', projectData);
      const createdProject = await response.data;
      await context.commit('STORE_PROJECT_ID', createdProject.project_id);
    }
  },

  mutations: {
    FETCH_PROJECTS(state, projects) {
      state.projects = projects;
    },

    STORE_PROJECT_ID(state, projectId) {
      state.selectedProjectId = projectId;
      localStorage.setItem('selectedProjectId', projectId);
    }
  }
};
