<script>
import { Doughnut } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: Doughnut,
  props: {
    colors: {
      type: Array,
      required: true
    },
    context: {
      type: Array,
      required: true
    },
    values: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            data: this.values,
            backgroundColor: this.colors
          }
        ],
        labels: this.context
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: '#ffff',
            font: {
              family: 'Barlow'
            }
          }
        },
        title: {
          display: true,
          fontSize: 20,
          padding: 20,
          text: 'Verteilung der Störungen',
          fontFamily: 'Barlow'
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 0,
            bottom: 10
          }
        },
        legend: {
          position: 'right',
          fontFamily: 'Barlow'
        }
      }
    };
  },

  mounted() {
    this.addPlugin([ChartDataLabels]);
    this.renderChart(this.chartData, this.options);
  }
};
</script>
