<template>
  <div>
    <div class="toggle-wrapping">
      <input
        id="toggle-on"
        class="toggle toggle-left"
        name="toggle"
        value="left"
        v-model="currentTab"
        type="radio"
        checked
      />
      <label for="toggle-on" class="btn-toggle">Gesamt Analyse</label>
      <input
        id="toggle-off"
        class="toggle toggle-right"
        name="toggle"
        value="right"
        v-model="currentTab"
        type="radio"
      />
      <label for="toggle-off" class="btn-toggle">Letzte Woche</label>
    </div>

    <transition :name="currentTab" mode="out-in">
      <component v-if="render" :is="currentTab"></component>
    </transition>

    <component is="style">
      {{ printSettings }}
    </component>
  </div>
</template>

<script>
import left from '@/components/kpi/OverallOverview';
import right from '@/components/kpi/LastWeek';

export default {
  components: {
    left,
    right
  },
  props: {
    perm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentTab: 'left',
      render: false
    };
  },

  beforeCreate() {
    this.$store.dispatch('socket/updateKpi');
  },

  computed: {
    dataAvailable() {
      return this.$store.state.projectData.kpi.loaded;
    },
    printSettings() {
      let printVars;
      if (this.currentTab === 'OverallOverview') {
        printVars = `@media print { @page { size: 460mm 380mm; } }`;
      } else {
        printVars = `@media print { @page { size: 460mm 270mm; } }`;
      }
      return printVars;
    }
  },

  watch: {
    dataAvailable: function(newValue) {
      this.render = newValue;
    }
  }
};
</script>
