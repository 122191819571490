<template>
  <div v-if="boardIndex === 0" class="vertical-control" :style="verticalScroll">
    <div :class="eckClass"></div>
    <div
      v-for="(area, index) in areas"
      :key="area.area_id"
      class="control-item-box-vertical-wrapper"
      :style="getStyle(area.area_height)"
    >
      <!-- areas -->
      <div class="vertical-header-wrapper">
        <div :title="area.area_name" class="vertical-header">{{ area.area_name }}</div>
        <!--  control buttons --> 
        <div v-if="perm" class="control-item-box-vertical">
          <!-- edit -->
          <div @click="edit({ id: area.area_id, value: area.area_name })">
            <base-icon class="icon icon__vc icon__vc--double">
              <IconEdit />
            </base-icon>
          </div>
          <!-- delete -->
          <div v-if="index" @click="deleteArea(area.area_id)">
            <base-icon class="icon icon__vc icon__vc--double" viewBox="0 0 32 32">
              <IconDelete />
            </base-icon>
          </div>
        </div>
      </div>

      <!-- subareas -->
      <div class="vertical-subheader-wrapper">
        <div
          v-for="subarea in area.subareas"
          :key="subarea.subarea_n"
          class="vertical-subheader"
          :style="getStyle(subarea.subarea_h, subarea.color_id)"
        >
          <div :title="area.area_name">
            {{ subarea.subarea_name }}
          </div>
          <!--  control buttons --> 
          <div class="control-item-box-vertical">
            <!-- plus -->
            <div @click="stepOne( { hDiff: 1, area, subarea } )">
              <base-icon class="icon icon__vc icon__vc--double" viewBox="0 0 32 32">
                <IconPlus />
              </base-icon>
            </div>
            <!-- minus -->
            <div v-if="subarea.subarea_h > 1" @click="stepOne( { hDiff: -1, area, subarea } )">
              <base-icon class="icon icon__vc icon__vc--double" viewBox="0 0 32 32">
                <IconMinus />
              </base-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* icons */
import IconEdit from '@/components/icons/IconEdit';
import IconDelete from '@/components/icons/IconDelete';
import IconMinus from '@/components/icons/IconMinus';
import IconPlus from '@/components/icons/IconPlus';

export default {
  components: {
    IconEdit,
    IconDelete,
    IconPlus,
    IconMinus
  },

  props: {
    areas: {
      type: Array,
      required: true
    },
    boardIndex: {
      type: Number,
      required: true
    },
    verticalScroll: {
      type: String,
      required: true
    },
    perm: {
      type: Boolean,
      default: false
    },
    eckClass: {
      type: String,
      default: 'eck-double'
    },
    type: {
      type: String,
      required: true
    }
  },

  methods: {
    edit(payload) {
      this.$emit('editAreas', payload);
    },

    deleteArea(areaId) {
      this.$emit('deleteArea', areaId);
    },

    stepOne({ hDiff, area, subarea }) {
      // get lastY to be moved:
      // + allHBefore_Areas
      const allAreas = this.$store.state.projectData[this.type].areas;
      const beforeAreas = allAreas.filter(a => a.area_n < area.area_n);
      const hBeforeAreas = beforeAreas.reduce((prev, cur) => prev + cur.area_height, 0);
      // + hBefore_Subareas
      const beforeSubAreas = area.subareas.filter(sa => sa.subarea_n <= subarea.subarea_n);
      const hBeforeSubAreas = beforeSubAreas.reduce((prev, cur) => prev + cur.subarea_h, 0);
      const startFromY = hBeforeAreas + hBeforeSubAreas;
      // check intersection
      if (hDiff == -1) {
        const yCoordinates = this.$store.state.projectData[this.type].activities.map(a => a.activity_pos_y);
        const arrayIntersection = yCoordinates.indexOf(startFromY)
        if (arrayIntersection > 0) {
          this.toastWarning();
          return          
        }
      }
      // new subareas array
      const newSubareas = JSON.parse(JSON.stringify(area.subareas));
      const newH = newSubareas[subarea.subarea_n - 1].subarea_h + hDiff;
      newSubareas[subarea.subarea_n - 1].subarea_h = newH
      // construct payload
      const payload = {};
      payload.action = 'update';
      payload.type = this.type;
      payload.areaId = area.area_id;
      payload.areaName = area.area_name;
      payload.areaH = area.area_height + hDiff;
      payload.subareas = newSubareas;
      payload.hDiff = hDiff;
      payload.projectId = this.$store.state.projects.selectedProjectId;
      payload.lastAreaY = startFromY;
      this.$store.dispatch(`socket/updateArea`, payload);
    },

    // utils
    toastWarning() {
      this.$toast.info('Nicht möglich: es gibt noch einige Aktivitäten im Bereich', {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000
      });
    },


    getStyle(h, colId) {
      let colBg = '#000000';
      let colTxt = '#ffffff';
      if (colId) {
        let color = this.$store.state.projectData.colors.areas.filter(
          (c) => c.color_id === colId
        )[0];
        colBg = color.hex_code;
        colTxt = color.hex_text;
      }
      return `height: ${h * 10}rem; color: ${colTxt}; background-color: ${colBg}; `;
    }
  }
};
</script>
