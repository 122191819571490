<template>
  <div>
    <div class="charts-wrapper-line-1">
      <div class="charts-wrapper-cards">
        <div class="chart-wrapper chart-wrapper__card-aez">
          <CardAez :aez="aezGesamt" />
        </div>
        <div class="chart-wrapper chart-wrapper__card-mpp">
          <CardMs :ist="msTotalIst" :soll="msTotalSoll" />
        </div>
      </div>
      <div class="chart-wrapper chart-wrapper__weeks-aez">
        <WeeksAez
          :kwList="kwList"
          :erledigt="erledigtList"
          :nichtErledigt="nichtErledigt"
          :aezList="aezListKw"
        />
      </div>
      <div class="chart-wrapper chart-wrapper__doughnut">
        <DisruptionDoughnut :colors="disrColors" :context="disrContext" :values="disrValues" />
      </div>
    </div>

    <div class="charts-wrapper-line-2">
      <div class="chart-wrapper chart-wrapper__trades-aez">
        <TradesAez :height="null" :width="null" :trades="tradesList" :aez="aezListGesamt" />
      </div>
      <div class="charts-wrapper-cards">
        <div class="chart-wrapper chart-wrapper__disruption-trades">
          <DisruptionTrades :disrDataset="disrDataset" :tradesList="tradesList" />
        </div>
        <div class="chart-wrapper chart-wrapper__milestone-flow">
          <MilestonesFlow :msWeeks="msWeeks" :soll="msSoll" :ist="msIst" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kpiDataMixin from '@/mixins/kpiDataMixin';

import DisruptionDoughnut from './charts/DisruptionDoughnut';
import WeeksAez from './charts/WeeksAez';
import TradesAez from './charts/TradesAez';
import DisruptionTrades from './charts/DisruptionTrades.vue';
import MilestonesFlow from './charts/MilestonesFlow.vue';
import CardAez from './charts/CardAez.vue';
import CardMs from './charts/CardMs.vue';

export default {
  components: {
    DisruptionDoughnut,
    WeeksAez,
    TradesAez,
    DisruptionTrades,
    MilestonesFlow,
    CardAez,
    CardMs
  },
  mixins: [kpiDataMixin]
};
</script>
