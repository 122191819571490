<template>
  <div class="modal">
    <div class="modal__content modal__content--filter">
      <div class="modal__close" @click="close">&times;</div>
      <h2 class="heading-secondary">Zeitraum auswählen</h2>
      <div class="filter-date-background">
        <div class="filter-date-scroller">
          <VueSlider
            v-model="filterSelection"
            :data="filterInputs"
            :data-value="'n'"
            :min-range="2"
            :max-range="12"
            :included="true"
            :tooltip="'always'"
            :tooltip-placement="['bottom', 'top']"
          >
            <template v-slot:label="{ label }">
              <div></div>
            </template>
          </VueSlider>
        </div>
      </div>

      <div class="table-scroll-filter">
        <div class="table-team-data" v-for="trade in trades" :key="trade.trade_id">
          <div class="filter-container" @click="toggleFilter(trade.trade_id)">
            <span class="checkbox-filter" :style="{ background: trade.hex_code }">
              <div class="checked" v-if="trade.selected"></div>
            </span>
            <div class="text-trade">{{ trade.trade_name }}</div>
          </div>
        </div>
      </div>

      <div class="button-flex-end">
        <div class="btn-modal__black" @click="submitData">Speichern</div>
      </div>
    </div>
  </div>
</template>

<script>
import modalKeyMixin from '@/mixins/modalKeyMixin';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import cloneDeep from 'lodash/cloneDeep';

export default {
  components: {
    VueSlider
  },
  mixins: [modalKeyMixin],
  props: {
    type: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      filterSelection: [],
      filterInputs: [],
      trades: [],
      filteredTrades: [],
      projectId: ''
    };
  },
  beforeMount() {
    const filterData = this.$store.getters['projectData/getFilterData'](this.type);
    this.filterSelection = filterData.value;
    this.filterInputs = filterData.data;
    this.projectId = this.$store.state.projects.selectedProjectId;

    const tradesClone = cloneDeep(this.$store.state.projectData.trades);
    /* filteredTrades */
    const storedFilteredTradesObject = localStorage.getItem('filteredTrades');
    if (storedFilteredTradesObject !== null) {
      const filteredTradesObject = JSON.parse(storedFilteredTradesObject);
      if (this.projectId in filteredTradesObject) {
        this.filteredTrades = filteredTradesObject[this.projectId];
      }
    } else {
      this.filteredTrades = [];
      const storedFilteredTradesObject = {};
      storedFilteredTradesObject[this.projectId] = [];
      localStorage.setItem('filteredTrades', JSON.stringify(storedFilteredTradesObject));
    }

    /* trades */
    this.trades = tradesClone.map((trade) => {
      if (this.filteredTrades.indexOf(trade.trade_id) >= 0) {
        trade.selected = false;
      } else {
        trade.selected = true;
      }
      return trade;
    });
  },

  methods: {
    submitData() {
      this.$store.dispatch('socket/updateProject', {
        action: 'setFilter',
        type: this.type,
        filterArray: [...this.filterSelection]
      });

      /* update local storage */
      const storedFilteredTradesObject = localStorage.getItem('filteredTrades');
      const filteredTradesObject = JSON.parse(storedFilteredTradesObject);
      filteredTradesObject[this.projectId] = this.filteredTrades;
      localStorage.setItem('filteredTrades', JSON.stringify(filteredTradesObject));

      this.close();
    },

    toggleFilter(selectedTradeId) {
      const selectedTradeIndex = this.trades.map((el) => el.trade_id).indexOf(selectedTradeId);
      const selectedTrade = this.trades[selectedTradeIndex];
      selectedTrade.selected = !selectedTrade.selected;
      this.trades.splice(selectedTradeIndex, 1, selectedTrade);
      if (!selectedTrade.selected) {
        this.filteredTrades.push(selectedTradeId);
      } else {
        this.filteredTrades.splice(this.filteredTrades.indexOf(selectedTradeId), 1);
      }
    },

    close() {
      this.$router.back();
    }
  }
};
</script>
