<template>
  <div class="modal">
    <div class="modal__content modal__content--subarea">
      <div class="modal__close" @click="close">&times;</div>
      <h2 class="heading-secondary heading-secondary__margin-bottom">FUNKTION</h2>

      <table class="table-trades">
        <thead>
          <tr>
            <th class="table-trade-item"></th>
            <th class="table-trade-item"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-subarea-inhalt">
            <!-- funktion name -->
            <td class="table-trade-input">
              <input
                autocomplete="off"
                type="text"
                class="form__input"
                id="Funktion"
                name="Funktion"
                required
                v-model.trim="tradeName"
                ref="inpTrade"
              />
              <label for="Funktion" class="form__label form__label--trade">Funktion/Gewerk</label>
            </td>
            <!-- Color Picker -->
            <td class="table-subarea-inhalt-item">
              <ColorPicker :colorId="colorId" @change-color="setColor" />
            </td>
          </tr>
        </tbody>
        <!-- Add styling here -->
        <p v-if="assignedObjects">
          <i>{{ `* Mit dieser Funktion werden insgesamt ${assignedObjects} Objekte verknüpft` }}</i>
        </p>
      </table>

      <div class="button-modal">
        <div class="button-float-left">
          <div class="btn-modal__darkred" v-if="showDelete" @click="askToDelete()">Löschen</div>
        </div>
        <div class="button-float-right">
          <div>
            <div class="btn-modal__black" @click="submitData()">Speichern</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

import modalKeyMixin from '@/mixins/modalKeyMixin';
import ColorPicker from '@/components/layout/picker/ColorPicker';

export default {
  components: {
    ColorPicker
  },
  mixins: [modalKeyMixin],
  data() {
    return {
      tradeId: '',
      tradeName: '',
      colorId: '',
      showDelete: false
    };
  },

  created() {
    const routeId = this.$route.params.id;
    // assign values
    if (routeId != 'new') {
      this.tradeName = cloneDeep(
        this.$store.state.projectData.trades.filter((t) => t.trade_id == routeId)[0].trade_name
      );
      this.colorId = cloneDeep(
        this.$store.state.projectData.trades.filter((t) => t.trade_id == routeId)[0].color_id
      );
      this.showDelete = true;
    } else {
      this.colorId = cloneDeep(this.$store.state.projectData.colors.members[0].color_id);
    }
    this.tradeId = routeId;
  },

  mounted() {
    this.$nextTick(() => this.$refs.inpTrade.focus());
  },

  computed: {
    assignedObjects() {
      const rmCards = this.$store.state.projectData.rm.activities.filter(
        (a) => a.trade_id === this.tradeId
      ).length;
      const gpaCards = this.$store.state.projectData.gpa.activities.filter(
        (a) => a.trade_id === this.tradeId
      ).length;
      const mppCards = this.$store.state.projectData.mpp.activities.filter(
        (a) => a.trade_id === this.tradeId
      ).length;
      const wvCards = this.$store.state.projectData.wv.activities.filter(
        (a) => a.trade_id === this.tradeId
      ).length;
      const sum = rmCards + gpaCards + mppCards + wvCards;
      return sum;
    }
  },

  methods: {
    setColor(id) {
      this.colorId = id;
    },

    submitData() {
      /* validation */
      if (this.tradeName == '') {
        this.$toast.info('Nicht möglich: das Feld Funktion darf nicht leer sein', {
          position: 'bottom-center',
          hideProgressBar: true,
          timeout: 3000
        });
        return;
      }
      const checkNames = this.$store.state.projectData.trades.filter(
        (t) => t.trade_name == this.tradeName
      );
      if (checkNames.length > 0 && this.tradeId == 'new') {
        this.$toast.info('Nicht möglich: solche Funtion ist schon vorhanden', {
          position: 'bottom-center',
          hideProgressBar: true,
          timeout: 3000
        });
        return;
      }
      /* end validation */
      const actionType = this.tradeId == 'new' ? 'add' : 'update';
      const payload = {
        action: actionType,
        trade: {
          tradeId: this.tradeId,
          tradeName: this.tradeName,
          colorId: this.colorId
        }
      };
      this.$store.dispatch(`socket/updateTrade`, payload);
      this.close();
    },

    askToDelete() {
      this.showDelete = false;
      if (this.assignedObjects) {
        this.$toast.error(
          `Achtung:\n${this.assignedObjects} verknüpfte Objekte werden zusammen mit der ausgewählten Funktion entgültig entfernt.\nHier klicken, um zu bestätigen.`,
          {
            position: 'bottom-center',
            hideProgressBar: false,
            timeout: 10000,
            onClick: () => this.deleteTrade()
          }
        );
      } else {
        this.deleteTrade();
      }
    },

    deleteTrade() {
      const payload = {
        action: 'delete',
        warning: this.assignedObjects ? true : false,
        trade: {
          tradeId: this.tradeId
        }
      };
      this.$store.dispatch(`socket/updateTrade`, payload);
      this.close();
    },

    close() {
      this.$router.back();
    }
  }
};
</script>
