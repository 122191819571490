<template>
  <div
    :class="{
      'post-it': activity.activity_type == 'square',
      'post-it-romb': activity.activity_type == 'romb',
      'activity-selected': activity.selected
    }"
    :style="{ backgroundColor: activity.hex_code, color: activity.hex_text }"
  >
    <div class="modal-post-it-position" :style="{ fontSize: autoTextSize }">
      <div
        v-if="type !== 'sb' && type !== 'rm'"
        class="text-post-it text-post-it__1"
        :style="{ borderColor: activity.rgb_border }"
        tabindex="-1"
      >
        {{ activity.activity_content_top }}
      </div>
      <div class="text-post-it text-post-it__2">
        {{ activity.activity_content_mid }}
      </div>
      <div
        v-if="type !== 'sb' && type !== 'rm'"
        class="text-post-it text-post-it__3"
        :style="{ borderColor: activity.rgb_border }"
      >
        {{ activity.activity_content_bottom }}
      </div>
    </div>
    <!-- Card is Checked -->
    <base-icon
      v-if="activity.activity_status == 'done'"
      class="check-post-it-icon"
      icon-name="checkbox"
    >
      <IconCheckmark />
    </base-icon>
    <!-- Card is Disrupted -->
    <div v-if="activity.activity_status == 'fail'" class="stourungen-post-it">
      {{ activity.disruption_context }}
    </div>
    <!-- Card is Marked (only for MPP) -->
    <div
      v-if="activity.activity_marked && activity.activity_type === 'square'"
      class="marked-item-square"
    ></div>
    <div
      v-if="activity.activity_marked && activity.activity_type === 'romb'"
      class="marked-item-romb"
    ></div>
  </div>
</template>

<script>
import styleMixin from '@/mixins/styleMixin';

import IconCheckmark from '@/components/icons/IconCheckmark';

export default {
  components: {
    IconCheckmark
  },

  mixins: [styleMixin],

  props: {
    activity: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'sb'
    }
  }
};
</script>
