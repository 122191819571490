import Vue from 'vue';
import VueRouter from 'vue-router';

//    auth
import LoginPage from '../pages/auth/LoginPage.vue';
import ForgotPage from '../pages/auth/ForgotPage.vue';
import ForgotNextPage from '../pages/auth/ForgotNextPage.vue';
import ResetNextPage from '../pages/auth/ResetNextPage.vue';
import ResetPage from '../pages/auth/ResetPage';
//    welcome
import ProjectsPage from '../pages/welcome/ProjectsPage.vue';
import NoLicensePage from '../pages/welcome/NoLicensePage.vue';
import NewProjectPage from '../pages/welcome/NewProjectPage';
//    project
import ProjectPage from '../pages/project/ProjectPage';
import ProjectOverview from '../pages/project/subpages/ProjectOverview';
import ProjectTeam from '../pages/project/subpages/ProjectTeam';
import ProjectAP from '../pages/project/subpages/ProjectAP';
import ProjectRM from '../pages/project/subpages/ProjectRM';
import ProjectKPI from '../pages/project/subpages/ProjectKPI';
import ProjectSB from '../pages/project/subpages/ProjectSB';
import ProjectGPA from '../pages/project/subpages/ProjectGPA';
import ProjectMPP from '../pages/project/subpages/ProjectMPP';
import ProjectWV from '../pages/project/subpages/ProjectWV';
//    modals
import ActivityView from '../pages/modals/ActivityView';
import TextView from '../pages/modals/TextView';
import SubareasView from '../pages/modals/SubareasView';
import MemberView from '../pages/modals/MemberView';
import TradeView from '../pages/modals/TradeView';
import FilterView from '../pages/modals/FilterView';
import ActionView from '../pages/modals/ActionView';

Vue.use(VueRouter);

// prettier-ignore
const routes = [
  { path: '/', redirect: { name: 'projects' } },
  { path: '/login', name: 'login', component: LoginPage },
  { path: '/forgot', name: 'forgot', component: ForgotPage },
  { path: '/forgot-next', name: 'forgot-next', component: ForgotNextPage },
  { path: '/password/reset/:id/:token', name: 'reset', component: ResetPage },
  { path: '/reset-next/', name: 'reset-next', component: ResetNextPage },

  // routes with auth
  { path: '/projects', name: 'projects', component: ProjectsPage, meta: { requiresAuth: true } },
  { path: '/projects/no-license', name: 'noLicense', component: NoLicensePage, meta: { requiresAuth: true } },
  { path: '/projects/new', name: 'newProject', component: NewProjectPage, meta: { requiresAuth: true } },
  { path: '/projects/edit/:id', name: 'editProject', component: NewProjectPage, meta: { requiresAuth: true } },
  /*      PROJECT ROUTES */
  { 
    path: '/projects/:projectName', name: 'project', component: ProjectPage, meta: { requiresAuth: true }, children: [

      { path: 'overview', name: 'projectPage', component: ProjectOverview, meta: { requiresAuth: true } },

      { path: 'team', name: 'team', component: ProjectTeam, meta: { requiresAuth: true }, children: [
          { path: 'member/:id', name: 'teamMember', component: MemberView, meta: { requiresAuth: true } },
          { path: 'trade/:id', name: 'teamTrade', component: TradeView, meta: { requiresAuth: true } } ] },

      { path: 'rm', name: 'rm', component: ProjectRM, meta: { requiresAuth: true }, children: [
          { path: 'risk/:id', name: 'activity-rm', component: ActivityView, meta: { requiresAuth: true } } ] },

      { path: 'kpi', name: 'kpi', component: ProjectKPI, meta: { requiresAuth: true } },

      { path: 'ap', name: 'ap', component: ProjectAP, meta: { requiresAuth: true }, children: [
        { path: 'action/:id', name: 'edit-ap', component: ActionView, meta: { requiresAuth: true } } ] },

      { path: 'sb', name: 'sb', component: ProjectSB, meta: { requiresAuth: true }, children: [
        { path: 'edit-board', name: 'edit-board-sb', component: TextView, meta: { requiresAuth: true } },
        { path: 'activity/:id', name: 'activity-sb', component: ActivityView, meta: { requiresAuth: true } },
        { path: 'edit-areas', name: 'edit-areas-sb', component: SubareasView, meta: { requiresAuth: true } } ] },

      { path: 'gpa', name: 'gpa', component: ProjectGPA, meta: { requiresAuth: true }, children: [
        { path: 'edit-board', name: 'edit-board-gpa', component: TextView, meta: { requiresAuth: true } },
        { path: 'activity/:id', name: 'activity-gpa', component: ActivityView, meta: { requiresAuth: true } },
        { path: 'edit-areas', name: 'edit-areas-gpa', component: SubareasView, meta: { requiresAuth: true } } ] },

      { path: 'mpp', name: 'mpp', component: ProjectMPP, meta: { requiresAuth: true }, children: [
        { path: 'activity/:id', name: 'activity-mpp', component: ActivityView, meta: { requiresAuth: true } },
        { path: 'edit-areas', name: 'edit-areas-mpp', component: SubareasView, meta: { requiresAuth: true } },
        { path: 'filter', name: 'filter-mpp', component: FilterView, meta: { requiresAuth: true } } ] },

      { path: 'wv', name: 'wv', component: ProjectWV, meta: { requiresAuth: true }, children: [
        { path: 'activity/:id', name: 'activity-wv', component: ActivityView, meta: { requiresAuth: true } },
        { path: 'edit-areas', name: 'edit-areas-wv', component: SubareasView, meta: { requiresAuth: true } },
        { path: 'filter', name: 'filter-wv', component: FilterView, meta: { requiresAuth: true } } ] }
    ]
  },
  // not found
  { path: '*', redirect: { name: 'projects' } }
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_BASE_URL,
  routes
});

// protection middleware:
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user');
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next({
        name: 'login'
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
