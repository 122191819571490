export default {
  /* sort boards by board_n. If it's wv or mpp additionaly filter by project filter 
  additionaly protect getter in case boards are still fetching */
  getBoards: (state) => (type) => {
    if (state[type].boards) {
      const sortedBoards = state[type].boards.sort((a, b) => a.board_n - b.board_n);
      // filter boards
      if (type === 'mpp' || type === 'wv') {
        return sortedBoards.filter(
          (board) =>
            board.board_n >= state.project[`filter_${type}`][0] &&
            board.board_n <= state.project[`filter_${type}`][1]
        );
      } else {
        return sortedBoards;
      }
    }
  },

  getAreas: (state) => (type) => {
      const areas = state[type].areas.sort((a, b) => a.area_n - b.area_n);
      // sort subareas either
      if (type !== 'rm') {
          areas.forEach((area) => {
            area.subareas = area.subareas.sort((a,b) => a.subarea_n - b.subarea_n)
          })
      }

      return areas
  },

  getHeight: (state) => (type) => {
    return state[type].areas.reduce((acc, area) => {
      return acc + area.area_height;
    }, 0);
  },

  getWidth: (state) => (type) => {
    if (state[type].boards) {
      return state[type].boards.reduce((acc, board) => {
        return acc + board.board_width;
      }, 0);
    }
  },

  getLastBoardId: (state) => (type) => {
    return state[type].boards.slice(-1)[0].board_id;
  },

  getLastActivityY: (state) => (type) => {
    const yArray = state[type].activities.map((a) => a.activity_pos_y);
    return Math.max(...yArray);
  },

  getFilterData: (state) => (type) => {
    const filterData = { value: state.project[`filter_${type}`].map((el) => Number(el)) };
    filterData.data = state[type].boards.map((board) => ({
      n: board.board_n,
      label: board.board_name
    }));
    return filterData;
  },

  getSelectedActivity: (state) => (type, id) => {
    return state[type].activities.filter((a) => a.activity_id === id)[0];
  },

  getUserMember: (state, getters, rootState) => {
    const userId = rootState.auth.user.user_id;
    return state.members.filter((m) => m.user_id === userId)[0];
  },

  getUserTrades: (state, getters, rootState, rootGetters) => {
    const userId = rootState.auth.user.user_id;
    const admin = rootGetters['auth/getMemberPermission'];
    if (admin) {
      return state.trades;
    } else {
      const allowedTradeIds = state.members.filter((m) => m.user_id === userId)[0].trade_id;
      const allowedTrades = state.trades.filter((t) => allowedTradeIds.includes(t.trade_id));
      return allowedTrades;
    }
  },

  getOffsetDays: (state) => {
    return state.project.weekday_offset;
  },

  getVirtualMap: (state, getters) => (type) => {
    /*
     * create virtual map of module { board_id, x, xV, y, occupied }
     */
    const h = getters.getHeight(type);
    const boards = getters.getBoards(type).map(({ board_width, board_id, board_n }) => {
      return { w: board_width, id: board_id, n: board_n };
    });

    /* construct board dictionary {board_id:n} */
    const boardEntries = [];
    boards.forEach((b) => {
      let key = b.id;
      let value = b.n;
      boardEntries.push([key, value]);
    });
    const boardDict = Object.fromEntries(boardEntries);

    /* get first x of each new board */
    let sum; // technic variable
    let firstXofBoard = boards.map((b) => (sum = (sum || 0) + b.w));
    firstXofBoard.unshift(0);
    firstXofBoard.pop();

    /* push into vm array */
    let vm = [];
    /* create an array with occupied field coordinates [`idxy`,...] */
    const occupiedFields = state[type].activities.map((a) => {
      /* get board_n from Dictionary */
      let boardN = boardDict[a.board_id];
      /* omit selected activity */
      if (!a.selected && boardN) {
        /* return comparison string "n1x2y3" */
        return `n${boardN}x${a.activity_pos_x}y${a.activity_pos_y}`;
      }
    });
    boards.forEach((board, index) => {
      for (let i = 1; i <= h; i++) {
        /* check if field is occupied */
        for (let j = 1; j <= board.w; j++) {
          const occ = occupiedFields.indexOf(`n${board.n}x${j}y${i}`) > 0;
          vm.push({ x_v: firstXofBoard[index] + j, x: j, y: i, id: board.id, occupied: occ });
        }
      }
    });
    return vm;
  },

  getBoardSubheader: (state) => (boardId) => {
    const board = state.wv.boards.filter((b) => b.board_id === boardId)[0];
    const offsetDays = state.project.weekday_offset;
    const boardSubname = board.board_subname;
    // offset dates
    const boardStartDate = new Date(board.board_start_date);
    const boardEndDate = new Date(board.board_end_date);
    const offsetStartDate = new Date(boardStartDate.setDate(boardStartDate.getDate() + offsetDays));
    const offsetEndDate = new Date(boardEndDate.setDate(boardEndDate.getDate() + offsetDays));

    // ['DD.MM.', ...]
    const datesList = [];
    for (let d = offsetStartDate; d <= offsetEndDate; d.setDate(d.getDate() + 1)) {
      const day = d.getDate()
      const month = d.getMonth() + 1
      const DD = day.toString().padStart(2, '0')
      const MM = month.toString().padStart(2, '0')
      datesList.push(` ${DD}.${MM}.`);
    }

    // ['ddd DD.MM.', ...]
    let skip = 0;
    const subheader = boardSubname.map((dayName, index) => {
      // skip weekend
      if (dayName.length == 2) return dayName + datesList[index + skip];
      else {
        skip = 1;
        return dayName;
      }
    });

    return subheader;
  },

  getExcel: (state, getters) => (type) => {
    const excelArrayOfObjects = []
    const activities = state[type].activities
    const trades = state.trades
    const boards = state[type].boards
    const areas = state[type].areas

    const subareas = []
    areas.forEach(area => {
        area.subareas.forEach((subarea => {
                const subareaObj = {...subarea}
                subareaObj.area_name = area.area_name
                subareaObj.area_h = area.area_height
                subareaObj.area_n = area.area_n
                subareas.push(subareaObj)
                }
            )
        )
    })
    const areasMap = subareas.map((x,i) => ({
        areaName: x.area_name,
        subareaName: x.subarea_name,
        areaHeightCumm: subareas.slice(0,i+1).map(({subarea_h}) => subarea_h).reduce((x,y) => x + y),
    }));

    activities.forEach(a => {
        let gewerk;
        try {
          gewerk = trades.filter(t => t.trade_id == a.trade_id)[0].trade_name;
        } catch(error) {
          gewerk = 'Nicht vorhanden'
          console.log(error);
        }
        const activityType = a.activity_type == 'romb' ? 'Meilenstein' : 'Prozess';

        const activitySubarea = areasMap.filter(area => area.areaHeightCumm >= a.activity_pos_y)[0]
        const areaName = activitySubarea.areaName
        const subareaName = activitySubarea.subareaName
        
        const board = boards.filter(b => b.board_id == a.board_id)[0]
        let timeAxis = []
        if (type == 'wv') {
          timeAxis = getters.getBoardSubheader(a.board_id)
        } else if (type == 'mpp') {
          timeAxis = board.board_subname
        } 
        const relTime = timeAxis[a.activity_pos_x - 1]
        
        excelArrayOfObjects.push({
            'Gewerk': gewerk,
            'Board': board.board_name,
            'Zeit Detail': relTime,
            'Bereich': areaName,
            'Unterbereich': subareaName,
            'Vorleistung': a.activity_content_top,
            'Aktivität':  a.activity_content_mid,
            'Nachleistung': a.activity_content_bottom,
            'Typ': activityType,
            'Status': a.activity_status,
            'Störung': a.disruption_context,
            'Farbcode': a.color_name
        })
    });
    
    return excelArrayOfObjects
  }
};
