<!-- prettier-ignore -->
<template>
  <BaseDropdown
    :dropdownList="disruptions"
    :passedElement="selectedDisruption"
    :listItems="{ text: 'disruption_context' }"
    @set-selection="selectDisruption"
  >
    <template v-slot:selection>
      {{ selectedDisruption.disruption_context }}
    </template>
  </BaseDropdown>
</template>

<script>
import BaseDropdown from '@/components/layout/BaseDropdown';

export default {
  components: { BaseDropdown },
  props: {
    disruptionId: {
      type: String,
      default: false
    }
  },

  computed: {
    disruptions() {
      return this.$store.state.projectData.disruptions;
    },

    selectedDisruption() {
      if (!this.disruptionId) {
        return { disruption_context: 'Störung auswählen' };
      } else {
        return this.disruptions.filter((d) => d.disruption_id === this.disruptionId)[0];
      }
    }
  },

  methods: {
    selectDisruption(payload) {
      this.$emit('set-disruption', payload.disruption_id);
    }
  }
};
</script>
