<template>
  <div class="modal">
    <div class="modal__content modal__content--table-members">
      <div class="modal__close" @click="close">&times;</div>
      <h2 class="heading-secondary heading-secondary__margin-bottom">
        {{ header }}
      </h2>

      <div class="modal__table-half">
        <div class="modal__member-half">
          <div class="form__group--member-modal">
            <input
              autocomplete="off"
              type="text"
              class="form__input"
              id="Vorname"
              name="Vorname"
              v-model.trim="firstname"
              required
            />
            <label for="Vorname" class="form__label">Vorname</label>
          </div>
          <div class="form__group--member-modal">
            <input
              autocomplete="off"
              type="text"
              class="form__input"
              id="Name"
              name="Name"
              v-model.trim="lastname"
              required
            />
            <label for="Name" class="form__label">Name</label>
          </div>
          <div class="form__group--member-modal">
            <input
              autocomplete="off"
              type="text"
              class="form__input"
              id="firma"
              name="firma"
              required
              v-model.trim="company"
            />
            <label for="firma" class="form__label">Firma</label>
          </div>
          <div class="form__group--member-modal">
            <input
              autocomplete="off"
              type="email"
              class="form__input"
              id="email"
              name="email"
              required
              v-model.trim="email"
              :disabled="mode === 'edit'"
            />
            <label v-if="mode !== 'edit'" for="email" class="form__label">E-Mail</label>
          </div>
          <div class="form__group--member-modal">
            <input
              autocomplete="off"
              type="text"
              class="form__input"
              id="number"
              name="number"
              required
              v-model.trim="phone"
            />
            <label for="text" class="form__label">Telefon Nummer</label>
          </div>

          <div class="checkbox-flex">
            <div class="checkbox-admin">
              <label class="custom-checkbox" aria-label="Mitglied hat admin Rechte">
                <input
                  name="admin"
                  class="checkbox"
                  type="checkbox"
                  v-model="memberType"
                  true-value="admin"
                  false-value="member"
                />
                <span class="checkmark"></span>
                <span for="admin" class="checkbox-label">Mitglied hat admin Rechte</span>
              </label>
            </div>
          </div>
        </div>

        <!-- DROPDOWN -->
        <div class="modal__member-half-dropdown">
          <div class="form__group--member-dropdowm" v-for="(tradeId, index) in tradeArray">
            <p class="funktion-text-dropdown">Funktion</p>
            <div class="trade-picker">
              <TradePicker :tradeId="tradeId" @change-trade="updateTrade(index, $event)" />
            </div>
            <div @click="removeTrade(index)" v-if="index + 1 == tradeArray.length">
              <base-icon class="icon-minus-funktion">
                <IconMinus />
              </base-icon>
            </div>
          </div>
          <!-- add next trade  -->
          <div class="add-gewerk-funktion no-print" @click="addTrade" v-if="tradeArray.length < 5">
            &#43; Gewerk/Funktion hinzufügen
          </div>
        </div>
      </div>

      <div class="button-modal">
        <div class="button-float-left">
          <div v-show="mode === 'edit'" class="btn-modal__red" @click="removeMember">
            Löschen
          </div>
        </div>
        <div class="button-float-right">
          <div v-if="mode === 'new'" class="btn-modal__black" @click="submitData()">
            Erstellen
          </div>
          <div v-else class="btn-modal__black" @click="submitData">Speichern</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modalKeyMixin from '@/mixins/modalKeyMixin';

import TradePicker from '@/components/layout/picker/TradePicker';
import IconMinus from '@/components/icons/IconMinus';

export default {
  components: { TradePicker, IconMinus },
  mixins: [modalKeyMixin],
  data() {
    return {
      header: 'Neues Team Mitglied',
      mode: 'new',
      memberId: 'new',
      userId: '',
      firstname: '',
      lastname: '',
      company: '',
      email: '',
      phone: '',
      memberType: 'member',
      tradeArray: []
    };
  },

  created() {
    // prepopulate form if edit
    if (this.$route.params.id !== 'new') {
      const filterStateMember = this.$store.state.projectData.members.filter(
        (el) => el.member_id === this.$route.params.id
      );
      const member = filterStateMember[0];
      this.firstname = member.user_firstname;
      this.lastname = member.user_lastname;
      this.company = member.user_company;
      this.email = member.user_email;
      this.phone = member.user_phone;
      this.memberType = member.member_type;
      this.tradeArray = member.trade_id;
      this.userId = member.user_id;
      this.memberId = this.$route.params.id;
      this.header = 'Mitglied Data';
      this.mode = 'edit';
    }
  },

  methods: {
    submitData() {
      /* validation */
      const checkEmails = this.$store.state.projectData.members.filter(
        (m) => m.user_email == this.email
      );
      if (checkEmails.length > 0 && this.memberId == 'new') {
        this.$toast.info('Nicht möglich: Benutzer mit solcher Email ist vorhanden', {
          position: 'bottom-center',
          hideProgressBar: true,
          timeout: 3000
        });
        return;
      }
      /* end validation */
      const member = {
        memberId: this.memberId,
        userId: this.userId,
        firstname: this.firstname,
        lastname: this.lastname,
        company: this.company,
        email: this.email,
        phone: this.phone,
        memberType: this.memberType
      };
      const trades = [...new Set(this.tradeArray.filter((el) => el.length > 10))];
      const action = this.memberId === 'new' ? 'add' : 'update';
      if (!this.email) {
        this.$toast.info('Email ist erforderlich', {
          position: 'bottom-center',
          hideProgressBar: true,
          timeout: 3000
        });
      } else {
        this.$store.dispatch('socket/updateMember', { member, trades, action });
        this.$router.back();
      }
    },

    removeMember() {
      this.$store.dispatch('socket/updateMember', { memberId: this.memberId, action: 'delete' });
      this.$router.back();
    },

    addTrade() {
      this.tradeArray.push(this.tradeArray.length.toString());
    },

    updateTrade(index, id) {
      this.tradeArray.splice(index, 1, id);
    },

    removeTrade(index) {
      this.tradeArray.splice(index, 1);
    },

    close() {
      this.$router.back();
    }
  }
};
</script>
