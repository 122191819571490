<template>
  <section class="login">
    <form @submit.prevent="submitForm" class="form">
      <h1 class="heading-secondary heading-secondary__margin-bottom">Reset Passwort</h1>
      <p class="info-text">
        Das passiert den Besten von uns. Gibt unter Deine E-Mail an und wir senden Dir weitere
        Informationen.
      </p>

      <div class="form__group">
        <input class="form__input" type="email" id="email" required v-model.trim="email" />
        <label for="email" class="form__label">Email</label>
      </div>

      <div class="button">
        <router-link class="info-text info-text__link" to="/login">
          Ich hab es mir doch errinert
        </router-link>
        <button class="btn-base">Weiter &rarr;</button>
      </div>
    </form>
  </section>
</template>

<script>
export default {
  data() {
    return {
      email: ''
    };
  },
  methods: {
    submitForm() {
      this.$store.dispatch('auth/reset', { email: this.email });
      this.$router.replace({ name: 'forgot-next' });
    }
  }
};
</script>
