export default {
  data() {
    return {
      clicks: 0,
      timer: null,
      delay: 300
    };
  },

  // if shift+click => select
  // if click => open or create
  // if double => check
  methods: {
    handleClick(event, x, y, activity) {
      // check if shift selection
      if (activity && event.shiftKey && this.checkPermission(activity.trade_id)) {
        this.toggleSelect(activity);
        return;
      } else if (event.shiftKey) {
        return;
      }
      // else open or check activity
      else {
        this.clicks++;
        if (this.clicks === 1) {
          this.timer = setTimeout(() => {
            this.clicks = 0;
            this.openOrCreateActivity(x, y, activity);
          }, this.delay);
        } else {
          clearTimeout(this.timer);
          this.toggleStatus(x, y, activity);
          this.clicks = 0;
        }
      }
    },

    toggleSelect(activity) {
      let updated = { ...activity };
      if ('selected' in updated) {
        updated.selected = !updated.selected;
      } else updated.selected = true;
      // dispatch action to update activity locally
      const payload = { activity: updated, type: this.type };
      this.$store.dispatch('projectData/io_updateActivity', payload);
    },

    openOrCreateActivity(x, y, activity) {
      if (activity) {
        /* check permission */
        if (!this.checkPermission(activity.trade_id)) return;
        /* open */
        this.$router.push({
          name: `activity-${this.type}`,
          params: { id: activity.activity_id, type: this.type }
        });
        this.unselectAll();
      } else {
        /* create */
        this.$router.push({
          name: `activity-${this.type}`,
          params: { id: 'new', x, y, boardId: this.board.board_id, type: this.type }
        });
        this.unselectAll();
      }
    },

    toggleStatus(x, y) {
      // check if any activity
      const activity = this.activities.filter(
        (a) => a.activity_pos_x == x && a.activity_pos_y == y
      )[0];
      if (activity) {
        /* check permission */
        if (!this.checkPermission(activity.trade_id)) return;
        // check status
        const status = activity.activity_status;
        if (status === 'fail') {
          console.log('Remove disruption first!');
          return;
        }
        const payload = { activity: { ...activity }, action: 'update' };
        payload.type = this.type;
        // dog-ear first if mpp
        if (this.type === 'mpp') {
          const marked = activity.activity_marked;
          if (!marked) {
            payload.activity.activity_marked = true;
            this.$store.dispatch(`socket/updateActivity`, payload);
            return;
          }
        }
        // toggle status
        if (status === 'active') {
          payload.activity.activity_status = 'done';
        } else if (status === 'done') {
          payload.activity.activity_status = 'active';
        }
        this.$store.dispatch(`socket/updateActivity`, payload);
      }
    },

    unselectAll() {
      this.$store.state.projectData[this.type].activities.forEach((activity) => {
        if (activity.selected) {
          activity.selected = false;
        }
      });
    },

    checkPermission(tradeId) {
      const allowedTradesIds = this.$store.getters['projectData/getUserTrades'].map(
        (t) => t.trade_id
      );
      return allowedTradesIds.includes(tradeId);
    },

    checkIfNotFiltered(tradeId) {
      let filteredTrades = localStorage.getItem('filteredTrades');
      if (filteredTrades !== null) {
        filteredTrades = JSON.parse(filteredTrades);
        const projectId = this.$store.state.projects.selectedProjectId;
        let uncheckedTrades = [];
        if (projectId in filteredTrades) {
          uncheckedTrades = filteredTrades[projectId];
        }
        return uncheckedTrades.indexOf(tradeId) < 0;
      } else {
        return true
      }
    }
  }
};
