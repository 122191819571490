<template>
  <div class="main-grid-view-wrapper">
    <!-- page -->
    <GridController
      v-for="(board, index) in boards"
      :key="board.board_id"
      type="gpa"
      :perm="perm"
      :board="board"
      :boardIndex="index"
      :activities="activities.filter((a) => a.board_id === board.board_id)"
      :areas="areas"
      :height="height"
      :lastBoardId="lastBoardId"
      :scrollX="scrollX"
      :scrollY="scrollY"
    >
      <StandardGrid
        type="gpa"
        :board="board"
        :activities="activities.filter((a) => a.board_id === board.board_id)"
        :areas="areas"
        :height="height"
      />
    </GridController>

    <!-- modal -->
    <transition name="pages" mode="out-in">
      <router-view type="gpa"></router-view>
    </transition>

    <!-- print page style -->
    <component is="style">
      {{ printSettings }}
    </component>
  </div>
</template>

<script>
import StandardGrid from '@/components/grid/StandardGrid';
import GridController from '@/components/grid/GridController';
import projectMainComponentMixin from '@/mixins/projectMainComponentMixin';

export default {
  components: {
    StandardGrid,
    GridController
  },
  mixins: [projectMainComponentMixin('gpa')]
};
</script>
