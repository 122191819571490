import axios from 'axios';

export default {
  namespaced: true,
  state() {
    return {
      user: {}
    };
  },

  actions: {
    async login({ commit }, credentials) {
      const response = await axios.post('/auth/login', credentials);
      if (response.data.token) {
        commit('SET_USER_DATA', response.data);
      } else {
        console.error('Failed to login');
      }
    },

    async reset(_, payload) {
      await axios.get(`auth/reset/${payload.email}`);
    },

    async newpass(_, payload) {
      await axios.put(`auth/password/reset/${payload.id}/${payload.token}`, {
        password: payload.password
      });
    },

    logout({ commit }) {
      commit('CLEAR_USER_DATA');
    },

    io_logout({ commit }) {
      commit('CLEAR_USER_DATA');
    }
  },

  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      localStorage.setItem('user', JSON.stringify(userData));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + userData.token;
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem('user');
      localStorage.removeItem('selectedProjectId');
      location.reload();
    }
  },

  getters: {
    authenticated(state) {
      return !!state.user;
    },

    getMemberPermission(state, getters, rootState, rootGetters) {
      const userType = state.user.user_type; // power vs common
      let memberType; // admin vs common
      const member = rootGetters['projectData/getUserMember'];
      if (member) {
        memberType = member.member_type;
      }
      // return permission level
      if (userType === 'power' || memberType === 'admin') {
        return true;
      }
      return false;
    },

    getUserPermission(state) {
      const userType = state.user.user_type; // power vs common
      return userType === 'power' ? true : false;
    }
  }
};
