<template>
  <div class="modal">
    <div class="modal__content modal__content--content-height" v-if="activity">
      <div class="modal__left">
        <div class="shape" v-if="activity.activity_status === 'active'">
          <div
            class="shape__square"
            :class="[activity.activity_type == 'square' ? 'shape-selected' : '']"
            @click="changeActivityType('square')"
          ></div>
          <div
            class="shape__romb"
            :class="[activity.activity_type == 'romb' ? 'shape-selected' : '']"
            @click="changeActivityType('romb')"
          ></div>
          <!-- line and square only for mpp visible -->
          <div v-if="type === 'mpp'" class="shape__line"></div>
          <div v-if="type === 'mpp'" class="shape__square" @click="toggleActivityMarked">
            <!-- change border-top color for active to black -->
            <div :class="[activity.activity_marked ? 'shape-marked' : 'marked-shape-item']"></div>
          </div>
        </div>
        <div class="form-dropdown">
          <div class="dropdown-container" v-if="activity.activity_status === 'active'">
            <TradePicker :tradeId="activity.trade_id" @change-trade="changeActivityTrade" />
          </div>
          <div class="dropdown-container" v-if="activity.activity_status != 'done'">
            <DisruptionPicker
              :disruptionId="activity.disruption_id"
              @set-disruption="changeDisruption"
            />
          </div>
        </div>
        <div class="button-fixed-bottom">
          <div
            v-if="activity.activity_status === 'active'"
            class="chek-btn"
            @click="changeActivityStatus('done')"
          >
            <svg class="chek-icon">
              <use xlink:href="@/assets/img/chek.svg#icon-check"></use>
            </svg>
            <span>Als erledigt markieren</span>
          </div>

          <div
            v-if="activity.activity_status === 'done'"
            class="chek-btn chek-btn__uncheck"
            @click="changeActivityStatus('active')"
          >
            <svg class="chek-icon chek-icon__uncheck">
              <use xlink:href="@/assets/img/chek.svg#icon-check"></use>
            </svg>
            <span>Als nicht erledigt markieren</span>
          </div>

          <div
            v-if="activity.activity_status === 'fail'"
            class="chek-btn chek-btn__uncheck"
            @click="changeActivityStatus('active')"
          >
            <base-icon
              class="chek-icon chek-icon__uncheck"
              icon-name="arrow-back"
              viewBox="0 0 32 32"
            >
              <IconArrowBack />
            </base-icon>
            <span>Störung widerrufen</span>
          </div>
        </div>
      </div>
      <!-- 

          Right side

      -->
      <div class="modal__right">
        <div class="modal__close" @click="close">&times;</div>

        <div
          class="card-color"
          :class="[activity.activity_type == 'romb' ? 'card-romb' : 'card-square']"
          :style="{ backgroundColor: activity.hex_code }"
        >
          <div
            class="modal-post-it-position"
            :style="{ color: activity.hex_text, fontSize: autoTextSize }"
          >
            <div
              v-if="type !== 'sb' && type !== 'rm'"
              :style="{ borderColor: activity.hex_text }"
              class="modal-text-post-it modal-text-post-it__1"
              @input="(e) => bindContent(e, 'newContentTop')"
              contenteditable="true"
            >
              {{ activity.activity_content_top }}
            </div>
            <div
              class="modal-text-post-it modal-text-post-it__2"
              @input="(e) => bindContent(e, 'newContentMid')"
              contenteditable="true"
            >
              {{ activity.activity_content_mid }}
            </div>
            <div
              v-if="type !== 'sb' && type !== 'rm'"
              :style="{ borderColor: activity.hex_text }"
              class="modal-text-post-it modal-text-post-it__3"
              @input="(e) => bindContent(e, 'newContentBottom')"
              contenteditable="true"
            >
              {{ activity.activity_content_bottom }}
            </div>
          </div>

          <!-- Check icon -->
          <base-icon
            v-if="activity.activity_status === 'done'"
            class="check-modal-icon"
            icon-name="checkbox"
          >
            <IconCheckmark />
          </base-icon>

          <!-- Störung Schield  -->
          <div v-if="activity.activity_status === 'fail'" class="stourungen-modal">
            {{ activity.disruption_context }}
          </div>

          <!-- Marked item (only for MPP) -->
          <div v-if="activity.activity_marked" class="marked-modal-item"></div>
        </div>
        <div class="button-modal">
          <div class="button-float-left">
            <div
              v-if="activity.activity_status === 'active'"
              class="btn-modal__red"
              @click="deleteActivity"
            >
              Löschen
            </div>
          </div>
          <div class="button-float-right">
            <div class="btn-modal__black" @click="submitData">Speichern</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modalKeyMixin from '@/mixins/modalKeyMixin';
import styleMixin from '@/mixins/styleMixin';

import TradePicker from '@/components/layout/picker/TradePicker';
import DisruptionPicker from '@/components/layout/picker/DisruptionPicker';
import IconCheckmark from '@/components/icons/IconCheckmark';
import IconArrowBack from '@/components/icons/IconArrowBack';

export default {
  components: {
    TradePicker,
    IconCheckmark,
    IconArrowBack,
    DisruptionPicker
  },

  mixins: [modalKeyMixin, styleMixin],

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      newContentTop: '',
      newContentMid: '',
      newContentBottom: ''
    };
  },

  created() {
    const passedId = this.$route.params.id;
    if (passedId === 'new') {
      const activity = {};
      activity.board_id = this.$route.params.boardId;
      activity.pos_x = this.$route.params.x;
      activity.pos_y = this.$route.params.y;
      try {
        activity.trade_id = this.$store.getters['projectData/getUserTrades'][0].trade_id;
        this.$store.dispatch(`socket/updateActivity`, { action: 'add', activity, type: this.type });
      } catch (error) {
        this.$toast.warning('Mindestens eine Funktion ist erforderlich', {
          position: 'bottom-center',
          hideProgressBar: true,
          timeout: 3000
        });
        this.$router.back();
      }
    }
  },

  beforeMount() {
    // pre populate content fields if activity exists
    const passedId = this.$route.params.id;
    if (passedId != 'new') {
      const passedActivity = this.activity;
      this.newContentTop = passedActivity.activity_content_top;
      this.newContentMid = passedActivity.activity_content_mid;
      this.newContentBottom = passedActivity.activity_content_bottom;
    }
  },

  computed: {
    activity() {
      const passedId = this.$route.params.id;
      if (passedId === 'new') {
        const newActivityId = this.$store.state.temp.selectedActivityId;
        if (newActivityId) {
          return this.$store.getters['projectData/getSelectedActivity'](this.type, newActivityId);
        }
      }
      return this.$store.getters['projectData/getSelectedActivity'](this.type, passedId);
    }
  },
  methods: {
    submitData() {
      const payload = { activity: { ...this.activity }, action: 'update' };
      payload.type = this.type;
      payload.activity.activity_content_top = this.newContentTop;
      payload.activity.activity_content_mid = this.newContentMid;
      payload.activity.activity_content_bottom = this.newContentBottom;
      this.$store.dispatch(`socket/updateActivity`, payload);
      this.$store.commit(`temp/CLEAR_SELECTION`);
      this.close();
    },

    bindContent(e, position) {
      const value = e.target.innerText;
      this[position] = value;
    },

    changeActivityTrade(tradeId) {
      const payload = { activity: { ...this.activity }, action: 'update' };
      payload.type = this.type;
      payload.activity.trade_id = tradeId;
      this.$store.dispatch(`socket/updateActivity`, payload);
    },

    changeActivityType(newType) {
      const payload = { activity: { ...this.activity }, action: 'update' };
      payload.type = this.type;
      payload.activity.activity_type = newType;
      this.$store.dispatch(`socket/updateActivity`, payload);
      this.activeShape = newType;
    },

    toggleActivityMarked(newType) {
      const payload = { activity: { ...this.activity }, action: 'update' };
      payload.type = this.type;
      payload.activity.activity_marked = !this.activity.activity_marked;
      this.$store.dispatch(`socket/updateActivity`, payload);
      this.activeShape = newType;
    },

    changeActivityStatus(newStatus) {
      const payload = { activity: { ...this.activity }, action: 'update' };
      payload.type = this.type;
      payload.activity.activity_status = newStatus;
      payload.activity.disruption_id = null;
      this.$store.dispatch(`socket/updateActivity`, payload);
    },

    changeDisruption(disruptionId) {
      const payload = { activity: { ...this.activity }, action: 'update' };
      payload.type = this.type;
      payload.activity.disruption_id = disruptionId;
      payload.activity.activity_status = 'fail';
      this.$store.dispatch(`socket/updateActivity`, payload);
    },

    deleteActivity() {
      const activityId = this.activity.activity_id;
      const payload = { action: 'delete', activityId, type: this.type };
      this.$store.dispatch(`socket/updateActivity`, payload);
      this.$router.back();
    },

    close() {
      this.$router.replace({
        name: this.type,
        params: {
          projectName: this.$store.state.projectData.project.project_name
            .toLowerCase()
            .split(' ')
            .join('_')
        }
      });
    }
  }
};
</script>

<style lang="css" scoped>
.shape-selected {
  background-color: black;
}
.shape-marked {
  width: 0;
  height: 0;
  border-top: 1.5rem solid black;
  border-left: 1.5rem solid transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  transition: 0.2s;
}
</style>
