export default {
  methods: {
    // open modals
    editBoard({ id, value }) {
      console.log({ id, value, type: this.type });
      this.$router.push({
        name: `edit-board-${this.type}`,
        params: { id, value, type: this.type }
      });
    },

    // areas
    editAreas({ id, value }) {
      this.$router.push({
        name: `edit-areas-${this.type}`,
        params: { id, value, type: this.type }
      });
    },

    openFilterView() {
      this.$router.push({
        name: `filter-${this.type}`
      });
    },

    addArea() {
      const payload = {};
      payload.action = 'add';
      payload.type = this.type;
      this.$store.dispatch(`socket/updateArea`, payload);
    },

    deleteArea(areaId) {
      // validation:
      const currentArea = this.$store.state.projectData[this.type].areas.filter(
        (a) => a.area_id === areaId
      )[0];
      const beforeAreas = this.$store.state.projectData[this.type].areas.filter(
        (a) => a.area_n < currentArea.area_n
      );
      const hBeforeAreas = beforeAreas.reduce((prev, cur) => prev + cur.area_height, 0);
      const hCurrentArea = currentArea.area_height;

      let deletedRows = [];
      for (let i = hBeforeAreas + 1; i <= hBeforeAreas + hCurrentArea; i++) {
        deletedRows.push(i);
      }
      /* - get all y coordinates*/
      const yCoordinates = this.$store.state.projectData[this.type].activities.map(
        (a) => a.activity_pos_y
      );
      /* - check intersection*/
      const arrayIntersection = yCoordinates.filter((val) => deletedRows.includes(val));

      if (arrayIntersection.length > 0) {
        this.toastWarning();
      } else {
        const payload = {};
        payload.action = 'delete';
        payload.type = this.type;
        payload.areaId = areaId;
        payload.projectId = this.$store.state.projects.selectedProjectId;
        payload.lastAreaY = hBeforeAreas + hCurrentArea;
        payload.hDiff = hCurrentArea * -1;
        this.$store.dispatch(`socket/updateArea`, payload);
      }
    },

    // boards actions
    updateWidth(payload) {
      if (
        this.activities.find((a) => a.activity_pos_x === this.board.board_width) &&
        payload.mode === 'reduce'
      ) {
        this.toastWarning();
      } else {
        payload.action = 'update';
        payload.field = 'width';
        payload.type = this.type;
        this.$store.dispatch('socket/updateBoard', payload);
      }
    },

    addNextBoard(currentN) {
      const nPlusOne = currentN + 1;
      const payloadBoard = { action: 'add', type: this.type };
      const payloadProject = { action: 'moveFilter', type: this.type, nPlusOne };
      this.$store.dispatch(`socket/updateBoard`, payloadBoard);
      this.$store.dispatch(`socket/updateProject`, payloadProject);
    },

    addNewBoard() {
      const payload = { action: 'add', type: this.type };
      this.$store.dispatch(`socket/updateBoard`, payload);
    },

    deleteBoard(boardId) {
      if (this.activities.length > 0) {
        console.log('There are some cards here...');
      } else {
        const payload = { action: 'delete', type: this.type, boardId };
        this.$store.dispatch(`socket/updateBoard`, payload);
      }
    },

    toastWarning() {
      this.$toast.info('Nicht möglich: es gibt noch einige Aktivitäten im Bereich', {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000
      });
    }
  }
};
