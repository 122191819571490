<template>
  <div>
    <div class="risk-wrapper">
      <!-- modal -->
      <transition name="pages" mode="out-in">
        <router-view type="rm"></router-view>
      </transition>

      <div class="risk-matrix-border-grid-container">
        <div class="risk-vertical-border">
          <div class="risk-vertical-border-sub-header">
            <h3 class="risk-sub-header-element-ver">gering</h3>
            <h3 class="risk-sub-header-element-ver">mittel</h3>
            <h3 class="risk-sub-header-element-ver">groß</h3>
          </div>
          <div class="risk-header-container-ver">
            <div class="risk-vertical-border-header">Eintrittswahrscheinlichkeit</div>
          </div>
        </div>
        <div class="risk-horizontal-border">
          <div class="risk-horizontal-border-sub-header">
            <h3 class="risk-sub-header-element-hor">gering</h3>
            <h3 class="risk-sub-header-element-hor">mittel</h3>
            <h3 class="risk-sub-header-element-hor">groß</h3>
          </div>
          <div class="risk-header-container-hor">
            <div class="risk-horizontal-border-header">Auswirkung</div>
          </div>
        </div>
        <div class="eck-risk-matrix"></div>

        <div class="risk-matrix-grid-container">
          <StandardGrid
            v-if="boards"
            type="rm"
            :board="boards[0]"
            :activities="activities"
            :areas="areas"
            :height="height"
          />
        </div>
      </div>
    </div>

    <!-- print page style -->
    <component is="style">
      {{ printSettings }}
    </component>
  </div>
</template>

<script>
import StandardGrid from '@/components/grid/StandardGrid';

import projectMainComponentMixin from '@/mixins/projectMainComponentMixin';

export default {
  components: {
    StandardGrid
  },
  mixins: [projectMainComponentMixin('rm')],
  computed: {
    printSettings() {
      const printVars = `@media print { @page { size: 400mm 220mm; } }`;
      return printVars;
    }
  }
};
</script>
