<template>
  <section class="section-form">
    <form @submit.prevent="submitData" class="form" autocomplete="off">
      <h2 class="heading-secondary heading-secondary__margin-bottom">
        Projektdaten
      </h2>
      <div class="form__group">
        <input
          autocomplete="off"
          type="text"
          class="form__input"
          :class="{ hasValue: projectData.projectName }"
          id="projectName"
          name="projectName"
          v-model.trim="projectData.projectName"
          required
        />
        <label for="projectName" class="form__label">Projektname</label>
      </div>
      <div class="form__group">
        <input
          autocomplete="off"
          type="date"
          class="form__input"
          :class="{ hasValue: projectData.projectStartDate }"
          id="projectStart"
          name="projectStartDate"
          v-model="projectData.projectStartDate"
          required
        />
        <label for="projectStart" class="form__label">Projektstart</label>
      </div>
      <div class="form__group">
        <input
          autocomplete="off"
          type="text"
          class="form__input"
          :class="{ hasValue: projectData.projectCity }"
          id="projectCity"
          name="projectCity"
          v-model.trim="projectData.projectCity"
          required
        />
        <label for="projectCity" class="form__label">Stadt</label>
      </div>
      <div class="form__group">
        <input
          autocomplete="off"
          type="text"
          class="form__input"
          :class="{ hasValue: projectData.projectStreet }"
          id="projectStreet"
          name="projectStreet"
          v-model.trim="projectData.projectStreet"
          required
        />
        <label for="projectStreet" class="form__label">Straße</label>
      </div>
      <div class="form__group">
        <input
          autocomplete="off"
          type="text"
          class="form__input"
          :class="{ hasValue: projectData.projectHouseN }"
          id="projectHouseN"
          name="projectHouseN"
          v-model.trim="projectData.projectHouseN"
          required
        />
        <label for="projectHouseN" class="form__label">Haußnummer</label>
      </div>
      <div class="form__group">
        <input
          autocomplete="off"
          type="number"
          class="form__input"
          :class="{ hasValue: projectData.projectZip }"
          id="projectPLZ"
          name="projectZip"
          v-model.trim="projectData.projectZip"
          required
        />
        <label for="projectPLZ" class="form__label">PLZ</label>
      </div>
      <div class="form__group">
        <input
          autocomplete="off"
          type="text"
          class="form__input"
          :class="{ hasValue: projectData.projectLicense }"
          id="projectLicense"
          name="projectLicense"
          v-model.trim="projectData.projectLicense"
          required
        />
        <label for="projectLicense" class="form__label">License Key</label>
      </div>
      <div class="button">
        <button class="btn-base" type="submit">Speichern</button>
      </div>
    </form>
  </section>
</template>

<script>
import moment from 'moment';
import { getWeekNum } from '@/helpers/getWeekNum.js';

import modalKeyMixin from '@/mixins/modalKeyMixin';

export default {
  mixins: [modalKeyMixin],
  data() {
    return {
      mode: 'add',
      formIsValid: true,
      oldDate: '',
      projectData: {
        projectName: '',
        projectStartDate: '',
        projectCity: '',
        projectStreet: '',
        projectHouseN: '',
        projectZip: '',
        projectLicense: ''
      }
    };
  },

  created() {
    // prepopulate form if edit mode
    const projectId = this.$route.params.id;
    if (projectId) {
      const selectedProject = this.$store.state.projectData.project;
      this.mode = 'edit';
      this.projectData.projectName = selectedProject.project_name;
      // calculate date
      const dd = moment(selectedProject.project_start_date).format('YYYY-MM-DD');
      this.oldDate = JSON.parse(JSON.stringify(dd));
      this.projectData.projectStartDate = dd;
      this.projectData.projectCity = selectedProject.project_city;
      this.projectData.projectStreet = selectedProject.project_street;
      this.projectData.projectHouseN = selectedProject.project_house_n;
      this.projectData.projectZip = selectedProject.project_zip;
      this.projectData.projectLicense = selectedProject.project_license;
    }
  },

  methods: {
    submitData() {
      const payload = this.projectData;
      if (this.mode === 'add') {
        payload.projectOwnerId = this.$store.state.auth.user.user_id;
        this.$store.dispatch('projects/addProject', payload);
        this.goToProject();
      }

      if (this.mode === 'edit') {
        if (this.validateDates()) {
          payload.action = 'updateData';
          this.$store.dispatch('socket/updateProject', payload);
          this.goToProject();
        } else {
          this.$toast.info(
            `Projektstart sollte in KW ${getWeekNum(new Date(this.oldDate))} liegen`,
            {
              position: 'bottom-center',
              hideProgressBar: true,
              closeButton: false
            }
          );
        }
      }
    },

    validateDates() {
      // check that old and new date are have the same week number
      const oldDate = new Date(this.oldDate);
      const newDate = new Date(this.projectData.projectStartDate);
      return getWeekNum(oldDate) == getWeekNum(newDate) ? true : false;
    },

    goToProject() {
      this.$router.replace({
        name: 'projectPage',
        params: {
          projectName: this.projectData.projectName
            .toLowerCase()
            .split(' ')
            .join('_')
        }
      });
    },

    close() {
      this.$router.back();
    }
  }
};
</script>
