<template>
  <router-view :perm="perm"></router-view>
</template>

<script>
import Vue from 'vue';
import connectSocket from '@/socket';

export default {
  created() {
    // connect to socket.io
    if (!Vue.prototype.$socket) {
      connectSocket();
    }

    // pass io to socket vuex module
    this.$store.commit('socket/SET_SOCKET', this.$socket);

    // pick up project data from local storage if any
    const selectedProjectId = localStorage.getItem('selectedProjectId');
    if (selectedProjectId) {
      this.$store.dispatch('socket/requestProject', selectedProjectId);
      this.$store.commit('projects/STORE_PROJECT_ID', selectedProjectId);
    }

    // alert error if disconnected
    this.$socket.on('disconnect', () => {
      this.$toast.error(
        `Verbindung zum Server wurde abgebrochen. 
        Hier klicken, um die Seite neue zu laden`,
        {
          position: 'bottom-center',
          hideProgressBar: true,
          timeout: 3600000,
          closeButton: false,
          onClick: () => {
            this.$router.push({ name: 'projectPage' });
            location.reload();
          }
        }
      );
    });
  },

  // pass permission level to all child components
  computed: {
    perm() {
      return this.$store.getters['auth/getMemberPermission'];
    }
  }
};
</script>
