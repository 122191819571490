<!-- prettier-ignore -->
<template>
  <BaseDropdown
    :dropdownList="risks"
    :passedElement="selectedRisk"
    :listItems="{ color: 'hex_code', text: 'activity_content_mid' }"
    :metadata="{ disabled: disabled }"
    @set-selection="selectRisk"
  >
    <template v-slot:selection>
      <span class="color-picker-shape" :style="{ background: selectedRisk.hex_code }"></span>{{ selectedRisk.activity_content_mid }}
    </template>
  </BaseDropdown>
</template>

<script>
import BaseDropdown from '@/components/layout/BaseDropdown';

export default {
  components: { BaseDropdown },
  props: {
    activityId: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    risks() {
      return this.$store.state.projectData.rm.activities;
    },

    selectedRisk() {
      if (!this.activityId) {
        return { activity_content_mid: 'Risiko auswählen' };
      } else {
        return this.risks.filter((r) => r.activity_id === this.activityId)[0];
      }
    }
  },

  methods: {
    selectRisk(payload) {
      this.$emit('change-risk', payload.activity_id);
    }
  }
};
</script>
