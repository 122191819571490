<template>
  <div>
    <TheHeader />
    <transition name="pages" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import TheHeader from './components/layout/TheHeader.vue';

export default {
  components: {
    TheHeader
  },

  created() {
    //check if user data exists to login automaticly
    const userString = localStorage.getItem('user');
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit('auth/SET_USER_DATA', userData);

      //add axios interceptor to log out if token is invalid
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response.status === 401) {
            this.$store.dispatch('auth/logout');
          }
          return Promise.reject(error);
        }
      );
    }
  }
};
</script>

<style>
@import './assets/styles/style.css';
@import './assets/styles/icon-font.css';
</style>
