<!-- prettier-ignore -->
<template>
  <div class="modal">
    <div class="modal__content modal__content--action">
      <div class="modal__close" @click="close">&times;</div>

      <input
        type="text"
        class="action-title-field"
        placeholder="Aktion Beschreibung"
        v-model="action_context"
        :disabled="action_archived"
        required
      />

      <div class="action-input-wrapper">
        <div class="action-input-field action-input-field__mem1">
          <div class="action-titel">
            Angefordert durch:
          </div>
          <div class="action-picker">
            <MemberPicker
              :memberId="action_owner"
              @change-member="setOwner"
              :disabled="action_archived"
            />
          </div>
        </div>

        <div class="action-input-field action-input-field__mem2">
          <div class="action-titel">
            Zuständig:
          </div>
          <div class="action-picker">
            <MemberPicker
              :memberId="action_responsible_id"
              @change-member="setResponsible"
              :disabled="action_archived"
            />
          </div>
        </div>

        <div class="action-input-field action-input-field__dat1">
          <div class="action-titel">
            Erfasst am:
          </div>
          <div class="action-wraper-data">
            <input
              type="date"
              class="action-data-end"
              required
              v-model="created_on"
              :disabled="action_archived"
            />
          </div>
        </div>

        <div class="action-input-field action-input-field__dat2">
          <div class="action-titel">
            Erledigt bis:
          </div>
          <div class="action-wraper-data">
            <input
              type="date"
              class="action-data-end"
              required
              v-model="action_duedate"
              :disabled="action_archived"
            />
          </div>
        </div>

      </div>

      <div class="filter-date-scroller">
        Fortschritt (%)
        <VueSlider
          :tooltip-placement="['bottom']"
          :tooltip="'always'"
          v-model="action_status"
          :disabled="action_archived"
        />
        </div>

        <div class="info-text info-text__kommentar ">Bezogen auf das Risiko</div>

        <div class="risk-dropdown-selected">
          <RiskPicker 
            :activityId="rm_id" 
            @change-risk="setRmLink"
            :disabled="action_archived"
          />
        </div>

      
      <div class="info-text info-text__kommentar ">Kommentar</div>
      <div
        class="action-title-field action-title-field__comments "
        :contenteditable="!action_archived"
        :class="[action_archived ? 'grayed-out' : '']"
        style="white-space: pre-line"
        @input="(e) => bindContent(e, 'new_comment')"
      >
        {{ action_comment }}
      </div>

      <div class="button-modal">
        <div class="button-float-left">
          <div
            v-if="mode == 'update' && !action_archived"
            class="btn-modal__darkred "
            @click="toggleActionArchive(true)"
          >
            Archivieren
          </div>
          <div
            v-show="mode == 'update' && action_archived"
            class="btn-modal__darkgreen"
            @click="toggleActionArchive(false)"
          >
            Wiederherstellen
          </div>
        </div>
        <div class="button-float-right">
          <div>
            <div class="btn-modal__black" @click="submitData">Speichern</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import MemberPicker from '@/components/layout/picker/MemberPicker.vue';
import RiskPicker from '@/components/layout/picker/RiskPicker';

import modalKeyMixin from '@/mixins/modalKeyMixin';

export default {
  components: {
    VueSlider,
    MemberPicker,
    RiskPicker
  },
  mixins: [modalKeyMixin],

  data() {
    return {
      mode: 'add',
      action_id: '',
      action_context: '',
      action_duedate: '',
      action_comment: '',
      new_comment: '',
      action_status: '',
      action_archived: false,
      action_owner: '',
      action_responsible_id: '',
      created_on: '',
      updated_at: '',
      rm_id: ''
    };
  },

  created() {
    // prepopulate form if update
    if (this.$route.params.id !== 'new') {
      this.mode = 'update';
      const action = this.$store.state.projectData.ap.filter(
        (el) => el.action_id === this.$route.params.id
      )[0];
      this.action_id = action.action_id;
      this.action_context = action.action_context;
      // this.action_duedate = this.getDate(action.action_duedate);
      this.action_duedate = moment(action.action_duedate).format('YYYY-MM-DD');

      this.action_comment = action.action_comment;
      this.new_comment = action.action_comment;
      this.action_status = action.action_status;
      this.action_archived = action.action_archived;
      this.action_owner = action.action_owner;
      this.action_responsible_id = action.action_responsible_id;
      // this.created_on = this.getDate(action.created_on);
      this.created_on = moment(action.created_on).format('YYYY-MM-DD');

      this.updated_at = action.updated_at;
      this.rm_id = action.rm_id;
    } else {
      const userId = this.$store.state.auth.user.user_id;
      const member = this.$store.state.projectData.members.filter((m) => m.user_id === userId)[0];
      this.action_owner = member.member_id;
      this.action_status = 0;
      // this.created_on = this.getDate(new Date());
      this.created_on = moment(new Date()).format('YYYY-MM-DD');
    }
  },

  methods: {
    submitData() {
      const payload = { action: this.mode, ap: {} };
      payload.ap.action_id = this.action_id;
      payload.ap.action_context = this.action_context;
      payload.ap.action_duedate = this.action_duedate;
      payload.ap.action_comment = this.new_comment;
      payload.ap.action_status = this.action_status;
      payload.ap.action_archived = this.action_archived;
      payload.ap.action_owner = this.action_owner;
      payload.ap.action_responsible_id = this.action_responsible_id;
      payload.ap.created_on = this.created_on;
      payload.ap.rm_id = this.rm_id;
      if (!this.action_duedate || !this.action_responsible_id) {
        this.$toast.info('"Zuständig" und "Erledigt bis" sind Pflichtangaben', {
          position: 'bottom-center',
          hideProgressBar: true,
          timeout: 3000
        });
      } else {
        this.$store.dispatch(`socket/updateAp`, payload);
        this.close();
      }
    },

    setOwner(payload) {
      this.action_owner = payload;
    },

    setResponsible(payload) {
      this.action_responsible_id = payload;
    },

    setRmLink(payload) {
      this.rm_id = payload;
    },

    bindContent(e, position) {
      const value = e.target.innerText;
      this[position] = value;
    },

    toggleActionArchive(status) {
      this.action_archived = status;
      this.submitData();
    },

    close() {
      this.$router.back();
    }
  }
};
</script>
