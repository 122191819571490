// socket will be connnected after passing authorization routes
// this function will be called inside of ProjectPage wrapper component
import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client';
import store from './store';

function connectSocket() {
  const options = {
    query: {
      userId: JSON.parse(localStorage.getItem('user')).user_id,
      token: JSON.parse(localStorage.getItem('user')).token
    }
  };
  const socketIo = new VueSocketIO({
    debug: false,
    connection: SocketIO(process.env.VUE_APP_API_URL, options),
    vuex: {
      store,
      actionPrefix: 'io_'
    }
  });
  Vue.use(socketIo);
}

export default connectSocket;
