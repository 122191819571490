<template>
  <div :class="wrapperClass">
    <!-- horizontal-control -->
    <HorizontalControlDouble
      v-if="type === 'mpp' || type === 'wv'"
      :perm="perm"
      :board="board"
      :type="type"
      :horizontalScroll="horizontalScroll"
      @openFilterView="openFilterView"
    />
    <HorizontalControlSingle
      v-else
      :perm="perm"
      :board="board"
      :boardIndex="boardIndex"
      :horizontalScroll="horizontalScroll"
      @editBoard="editBoard"
      @deleteBoard="deleteBoard"
      @updateWidth="updateWidth"
    />

    <!-- vertical-control -->
    <VerticalControl
      :perm="perm"
      :areas="areas"
      :boardIndex="boardIndex"
      :eckClass="eckClass"
      :verticalScroll="verticalScroll"
      :type="type"
      @editAreas="editAreas"
      @deleteArea="deleteArea"
    />

    <!-- add board -->
    <div
      v-if="board.board_id === lastBoardId && (type === 'wv' || type === 'mpp') && perm"
      class="add-control add-control__board no-print"
      @click="addNextBoard(board.board_n)"
    >
      {{ type === 'wv' ? 'Nächste Woche' : 'Nächsten Monat' }}
    </div>
    <div
      v-if="board.board_id === lastBoardId && (type === 'sb' || type === 'gpa') && perm"
      class="add-control add-control__board no-print"
      @click="addNewBoard"
    >
      Neues Board
    </div>

    <!-- add area -->
    <div
      v-if="boardIndex === 0 && perm"
      class="add-control add-control__area no-print"
      @click="addArea"
    >
      Neuer Bereich
    </div>

    <!--  grid -->
    <div class="grid-control">
      <slot>GRID IS HERE</slot>
    </div>
  </div>
</template>

<script>
import HorizontalControlSingle from './HorizontalControlSingle';
import HorizontalControlDouble from './HorizontalControlDouble';

import VerticalControl from './VerticalControl';

import gridControlStyleMixin from '@/mixins/gridControlStyleMixin';
import gridControlStoreMixin from '@/mixins/gridControlStoreMixin';

export default {
  components: {
    HorizontalControlSingle,
    HorizontalControlDouble,
    VerticalControl
  },
  mixins: [gridControlStyleMixin, gridControlStoreMixin],
  props: {
    board: {
      type: Object,
      required: true
    },
    boardIndex: {
      type: Number,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    areas: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    lastBoardId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    perm: {
      type: Boolean,
      default: false
    },
    scrollX: {
      type: Number,
      default: 0
    },
    scrollY: {
      type: Number,
      default: 0
    }
  }
};
</script>
