<template>
  <header class="header">
    <router-link to="/" class="header-label">
      <h2 class="header__text">simplean</h2>
    </router-link>

    <transition name="header" mode="out-in">
      <div class="header-project-name-wrapper" :key="$route.fullPath">
        <div class="header-board-name" v-if="moduleName">
          <router-link class="header-board-name" :to="{ name: 'projectPage' }">
            <!--back arrow-->
            <base-icon
              v-if="!showEdit && projectName"
              icon-name="arrowLeft"
              class="arrow-left-icon no-print"
            >
              <IconArrowLeft />
            </base-icon>
            {{ projectName }}{{ moduleName }}
          </router-link>
        </div>

        <!--edit pencil-->
        <router-link v-if="showEdit" :to="{ name: 'editProject', params: { id: projectId } }">
          <svg class="edit-project-icon">
            <use xlink:href="@/assets/img/edit.svg#icon-edit"></use>
          </svg>
        </router-link>
        <div v-else class="project-icon-wrapper">
          <!--download-->
          <div class="edit-project-icon no-print" @click="printPage">
            <base-icon icon-name="Download PDF">
              <IconDownload />
            </base-icon>
          </div>
          <!--export xlsx-->
          <div v-if="showExcel" class="edit-project-icon no-print" @click="loadExcel">
            <base-icon icon-name="Download Excel">
              <IconTable />
            </base-icon>
          </div>
        </div>
      </div>
    </transition>

    <div class="button">
      <button v-if="showButtons" class="btn-base button__logout no-print" @click="logout">
        Logout
      </button>
    </div>
  </header>
</template>

<script>
import { utils, writeFileXLSX } from 'xlsx';

import IconDownload from '@/components/icons/IconDownload';
import IconArrowLeft from '@/components/icons/IconArrowLeft';
import IconTable from '@/components/icons/IconTable';

export default {
  components: {
    IconDownload,
    IconArrowLeft,
    IconTable
  },
  computed: {
    perm() {
      return this.$store.getters['auth/getMemberPermission'];
    },

    showButtons() {
      return this.$route.meta.requiresAuth;
    },

    showEdit() {
      return this.$route.name === 'projectPage' && this.perm && this.projectId;
    },

    showExcel() {
      return this.$route.name == 'mpp' || this.$route.name == 'wv'
    },

    projectName() {
      return this.$store.state.projectData.project.project_name;
    },

    projectId() {
      return this.$store.state.projectData.project.project_id;
    },

    moduleName() {
      const routeName = this.$route.name;
      switch (routeName) {
        case 'projectPage':
          return ' ';
        case 'sb':
          return ': SANDKASTEN';
        case 'team':
          return ': TEAM';
        case 'rm':
          return ': RISIKOMATRIX';
        case 'ap':
          return ': AKTIONSPLAN';
        case 'gpa':
          return ': GESAMTPROZESSANALYSE';
        case 'mpp':
          return ': MEILENSTEIN- UND PHASENPLAN';
        case 'wv':
          return ': WOCHENVORSCHAU';
        case 'kpi':
          return ': PROJEKTKENNZAHLEN';
        default:
          return false;
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch(`auth/logout`).then(() => {
        this.$router.replace({ name: 'login' });
      });
    },

    getFileName() {
      const datestmp = new Date().toISOString().slice(0, 10);
      const projectString = this.projectName
                                .toLowerCase()
                                .split(' ')
                                .join('_')
      return `${datestmp}_${this.$route.name}_${projectString}`;
    },

    printPage() {
      window.scrollTo(0, 0);
      setTimeout(() => {
        document.title = this.getFileName();
        window.print();
        document.title = 'simplean';
      }, 500);
    },

    loadExcel() {
      const boardType = this.$route.name;
      const excelArrayOfObjects = this.$store.getters['projectData/getExcel'](boardType);

      const ws = utils.json_to_sheet(excelArrayOfObjects);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, `simplean_${boardType}`);
      const fileName = this.getFileName() + '.xlsx'
      writeFileXLSX(wb, fileName);
    }
  }
};
</script>
