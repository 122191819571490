<template>
  <div>
    <!-- toggle -->
    <div class="toggle-control-wrapping">
      <div class="toggle-wrapping">
        <input
          id="toggle-on"
          class="toggle toggle-left"
          name="toggle"
          :value="false"
          v-model="archived"
          type="radio"
          checked
        />
        <label for="toggle-on" class="btn-toggle">Aktuell</label>
        <input
          id="toggle-off"
          class="toggle toggle-right"
          name="toggle"
          :value="true"
          v-model="archived"
          type="radio"
        />
        <label for="toggle-off" class="btn-toggle">Archiv</label>
      </div>
      <div v-if="!archived" class="add-control add-control__team no-print" @click="addAction">
        Neue Aktion
      </div>
    </div>

    <div class="table-scroll">
      <table class="fixed_header">
        <thead class="table-head table-ap">
          <tr>
            <th
              @click="sort('created_on')"
              class="table-simplean thead-data"
              :class="{ sorted: this.currentSort == 'created_on' }"
            >
              Erfasst am
            </th>
            <th
              @click="sort('action_owner')"
              class="table-simplean thead-name"
              :class="{ sorted: this.currentSort == 'action_owner' }"
            >
              Angefordert durch
            </th>
            <th
              @click="sort('action_context')"
              class="table-simplean"
              :class="{ sorted: this.currentSort == 'action_context' }"
            >
              Aktion
            </th>
            <th
              @click="sort('action_responsible_id')"
              class="table-simplean thead-name"
              :class="{ sorted: this.currentSort == 'action_responsible_id' }"
            >
              Zuständig
            </th>
            <th
              @click="sort('action_duedate')"
              class="table-simplean thead-data"
              :class="{ sorted: this.currentSort == 'action_duedate' }"
            >
              Erledigt bis
            </th>
            <th
              @click="sort('action_comment')"
              class="table-simplean"
              :class="{ sorted: this.currentSort == 'action_comment' }"
            >
              Kommentar
            </th>
            <th
              @click="sort('action_status')"
              class="table-simplean"
              :class="{ sorted: this.currentSort == 'action_status' }"
            >
              Status
            </th>
          </tr>
        </thead>

        <tbody class="tbody-shadow">
          <tr
            class="table-action-inhalt"
            v-for="action in actions"
            :key="action.action_id"
            @click="editAction(action.action_id)"
          >
            <td class="table-simplean">{{ formatDate(action.created_on) }}</td>
            <td class="table-simplean" v-html="member(action.action_owner)"></td>
            <td class="table-simplean accent">{{ action.action_context }}</td>
            <td class="table-simplean" v-html="member(action.action_responsible_id)"></td>
            <td class="table-simplean">{{ formatDate(action.action_duedate) }}</td>
            <td class="table-simplean" style="white-space: pre-line">
              {{ action.action_comment }}
            </td>
            <td class="table-simplean">
              <div class="progress-circle" :data-progress="action.action_status"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <router-view></router-view>

    <!-- print page style -->
    <component is="style">
      {{ printSettings }}
    </component>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    perm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      archived: false,
      render: false,
      currentSort: 'action_duedate',
      currentSortDir: 'asc',
      tableHeight: 593,
      tableWidth: 420
    };
  },

  mounted() {
    // calculate page size properties
    let table = document.getElementsByClassName('table-scroll')
    if (table.item(0)) {
      let coeff = 3
      this.tableHeight = Math.round(table[0].scrollHeight / coeff)
      this.tableWidth = Math.round(table[0].scrollWidth / coeff)
    }
  },

  computed: {
    actions() {
      // sort
      if (this.$store.state.projectData.ap.length) {
        const actions = this.$store.state.projectData.ap.sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir == 'desc') modifier = -1;
          // sorting
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        });

        // toggle archive
        if (actions.length) {
          return actions.filter((a) => a.action_archived === this.archived);
        }
      }
    },
    members() {
      return this.$store.state.projectData.members;
    },

    printSettings() {
      const printVars = `@media print { @page { size: ${this.tableWidth}mm ${this.tableHeight}mm; } }`;
      console.log(printVars);
      return printVars;
    }
  },

  methods: {
    addAction() {
      this.$router.push({ name: 'edit-ap', params: { id: 'new' } });
    },
    editAction(id) {
      this.$router.push({ name: 'edit-ap', params: { id } });
    },
    member(id) {
      const member = this.members.filter((m) => m.member_id === id)[0];
      return `
        <span>${member.user_firstname} ${member.user_lastname}</span>`;
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    sort(sortField) {
      //if sortField == current sort, reverse
      if (sortField === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = sortField;
    }
  }
};
</script>
