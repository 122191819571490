<template>
  <div class="modal">
    <div class="modal__content modal__content--subarea">
      <div class="modal__close" @click="close">&times;</div>
      <h2 class="edit-view-text">Teilprojekt</h2>
      <div class="edit-input-wrapper">
        <input class="edit-input-modal" type="text" v-model.trim="areaName" />
      </div>
      <!-- Bereiche Controller -->
      <table class="table-subarea">
        <thead class="table-subarea-header">
          <tr>
            <th class="table-subarea-item">
              <div></div>
            </th>
            <th class="table-subarea-item">Bereich</th>
            <th class="table-subarea-item">Farbe</th>
            <th class="table-subarea-item">Höhe</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="table-subarea-inhalt"
            v-if="subareas"
            v-for="(subarea, index) in subareas"
            :key="subarea.subarea_n"
          >
            <!-- minus berecich -->
            <td class="table-subarea-inhalt-item" >
              <div v-if="index == subareas.length - 1 && index != 0" @click="removeSubarea(subarea.subarea_n)">
                <base-icon class="icon-minus-bereich" viewBox="0 0 32 32">
                  <IconMinus />
                </base-icon>
              </div>
            </td>
            <!-- subarea name -->
            <td class="table-subarea-inhalt-item">
              <input class="subarea-input" type="text" v-model.trim="subarea.subarea_name" />
            </td>
            <!-- Color Picker -->
            <td class="table-subarea-inhalt-item">
              <ColorPicker
                set="areas"
                :colorId="subarea.color_id"
                @change-color="setAreaColor(subarea.subarea_n, ...arguments)"
              />
            </td>
            <!-- Höhe -->
            <td class="table-subarea-inhalt-item">
              <div class="add-bereich-title-wrapper">
                <input class="subarea-input subarea-input__hohe" v-model="subarea.subarea_h" disabled/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- button neue bereich-->
      <div class="add-subarea no-print" @click="addSubarea">
        &#43; Neue Bereich
      </div>
      <!-- button save -->
      <div class="button-flex-end">
        <div class="btn-modal__black" @click="submitData">Speichern</div>
      </div>
    </div>
  </div>
</template>

<script>
import modalKeyMixin from '@/mixins/modalKeyMixin';
import ColorPicker from '@/components/layout/picker/ColorPicker';
/* icons */
import IconPlus from '@/components/icons/IconPlus';
import IconMinus from '@/components/icons/IconMinus';

export default {
  components: {
    ColorPicker,
    IconPlus,
    IconMinus
  },
  mixins: [modalKeyMixin],

  data() {
    return {
      type: '',
      areaName: '',
      areaId: '',
      subareas: []
    };
  },

  created() {
    this.type = this.$route.params.type;
    this.areaName = this.$route.params.value;
    this.areaId = this.$route.params.id;
    this.subareas = JSON.parse(
      JSON.stringify([
        ...this.$store.state.projectData[this.$route.params.type].areas.filter(
          (area) => area.area_id === this.areaId
        )[0].subareas
      ])
    );
  },

  methods: {
    setAreaColor(subareaN, colorId) {
      let subAreaToChange = this.subareas.filter((sa) => sa.subarea_n === subareaN)[0];
      subAreaToChange.color_id = colorId;
    },

    addSubarea() {
      // define last subarea_n
      let subareaN = Math.max(...this.subareas.map((o) => o.subarea_n), 0);
      // get first col id
      let firstColId = this.$store.state.projectData.colors.areas[0].color_id;
      // add subarea locally
      this.subareas.push({
        area_id: this.areaId,
        color_id: firstColId,
        subarea_name: 'Unterbereich',
        subarea_h: 4,
        subarea_n: subareaN + 1
      });
    },

    removeSubarea(sub_n) {
      this.subareas = this.subareas.filter((sa) => sa.subarea_n !== sub_n);
    },

    submitData() {
      /* Check if there are any cards in removing fields: */
      /* - calculate deleted rows */
      const currentArea = this.$store.state.projectData[this.type].areas.filter(
        (a) => a.area_id === this.areaId
      )[0];
      const newH = this.calculateH(this.subareas);
      const oldH = currentArea.area_height;
      const beforeAreas = this.$store.state.projectData[this.type].areas.filter(
        (a) => a.area_n < currentArea.area_n
      );
      const hBeforeAreas = beforeAreas.reduce((prev, cur) => prev + cur.area_height, 0);
      let deletedRows = [];
      if (newH < oldH) {
        let first = hBeforeAreas + newH + 1;
        let last = hBeforeAreas + oldH;
        for (let i = first; i <= last; i++) {
          deletedRows.push(i);
        }
      }
      /* - get all y coordinates*/
      const yCoordinates = this.$store.state.projectData[this.type].activities.map(
        (a) => a.activity_pos_y
      );
      /* - check intersection*/
      const arrayIntersection = yCoordinates.filter((val) => deletedRows.includes(val));
      if (arrayIntersection.length > 0) {
        this.toastWarning();
      } else {
        // construct payload
        const payload = {};
        payload.action = 'update';
        payload.type = this.type;
        payload.areaId = this.areaId;
        payload.areaName = this.areaName;
        payload.areaH = this.calculateH(this.subareas);
        payload.subareas = this.subareas;
        payload.hDiff = newH - oldH;
        payload.projectId = this.$store.state.projects.selectedProjectId;
        payload.lastAreaY = hBeforeAreas + oldH;
        // call backend & close
        this.$store.dispatch(`socket/updateArea`, payload);
        this.close();
      }
    },

    // utils
    toastWarning() {
      this.$toast.info('Nicht möglich: es gibt noch einige Aktivitäten im Bereich', {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000
      });
    },

    calculateH(subareaArray) {
      /* sum up all subareas h to find area h */
      return subareaArray.reduce((prev, cur) => Number(prev) + Number(cur.subarea_h), 0);
    },

    getCurrentAreaMaxY() {
      /* calculate maximum Y of given area */
      const areas = this.$store.getters[`projectData/getAreas`](this.type);
      const currentAreaN = this.$store.state.projectData[this.type].areas.filter(
        (a) => a.area_id === this.areaId
      )[0].area_n;
      const areasBefore = areas.filter((a) => a.area_n <= currentAreaN);
      const currentAreaMaxY = areasBefore.reduce((acc, area) => {
        return acc + area.area_height;
      }, 0);
      return currentAreaMaxY;
    },

    close() {
      this.$router.back();
    }
  }
};
</script>
