<script>
import { HorizontalBar } from 'vue-chartjs';

export default {
  extends: HorizontalBar,
  props: {
    trades: {
      type: Array,
      required: true
    },
    aez: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartData: {
        labels: this.trades,
        datasets: [
          {
            label: 'AEZ',
            backgroundColor: '#000000',
            data: this.aez
          }
        ]
      },
      options: {
        aspectRatio: 0.75,

        scales: {
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                fontFamily: 'Barlow'
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                min: 0,
                max: 100,
                fontFamily: 'Barlow'
              }
            }
          ]
        },

        plugins: {
          datalabels: {
            color: '#ffff',
            formatter: (value) => value + ' %',
            font: {
              family: 'Barlow'
            }
          }
        },
        title: {
          display: true,
          fontSize: 20,
          padding: 20,
          text: 'AEZ pro Gewerk',
          fontFamily: 'Barlow'
        },
        layout: {
          padding: {
            left: 20,
            right: 30,
            top: 0,
            bottom: 25
          }
        },
        legend: {
          display: false
        }
      }
    };
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>
