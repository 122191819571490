export default {
  created() {
    window.addEventListener('keydown', this.onKeydown);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeydown);
  },

  methods: {
    onKeydown(e) {
      // close on esc
      if (e.key === 'Escape') {
        this.close();
      }
      // submit in enter, omit Shift + Enter
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        this.submitData();
      }
    }
  }
};
