<!-- prettier-ignore -->
<template>
  <div class="wrapper-dropdown" :class="[metadata.disabled ? 'grayed-out' : '']">

    <!-- selection -->
    <div class="wrapper-dropdown-selected" @click.stop="toggleList()">
      <div v-if="selection && !active">
        <slot name="selection"></slot>
      </div>
      <input class="dropdown-searchbox" ref="searchbox" v-else type="text" placeholder="Suche..." v-model="searchInput">
    </div>

    <!-- list -->
    <transition name="drop-down" mode="out-in">
      <ul class="dropdown" v-show="active">
        <li class="dropdown-color-picker-list" @click="setSelection(element)" v-for="(element, index) in filteredItems" :key="index">
          <span v-if="listItems.color" class="color-picker-shape" :style="{ background: element[listItems.color] }"></span>
          <span v-if="listItems.text">{{ element[listItems.text] }}</span>
          <span v-if="listItems.text2" style="padding-left:5px;">{{ element[listItems.text2] }}</span>
        </li>
      </ul>
    </transition>

  </div>
</template>

<script>
export default {
  props: {
    dropdownList: {
      type: Array,
      required: true
    },
    passedElement: {
      type: Object,
      required: false
    },
    metadata: {
      type: Object,
      default: function() {
        return {
          disabled: false
        };
      }
    },
    listItems: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      active: false,
      selection: this.passedElement,
      searchInput: ''
    };
  },

  methods: {
    setSelection(id) {
      this.selection = id;
      this.active = false;
      this.$emit('set-selection', this.selection);
    },

    toggleList() {
      if (!this.metadata.disabled) {
        this.active = !this.active;
        if (this.active) {
          this.$nextTick(() => this.$refs.searchbox.focus());
        }
      }
    },

    closeList(e) {
      if (e.target.parentNode) {
        this.active = false;
        this.searchInput = '';
      }
    }
  },

  computed: {
    filteredItems() {
      const notNullList = this.dropdownList.filter((el) => el[this.listItems.text]);
      return notNullList.filter((el) =>
        this.listItems.text2
          ? el[this.listItems.text].toLowerCase().includes(this.searchInput.toLowerCase()) ||
            el[this.listItems.text2].toLowerCase().includes(this.searchInput.toLowerCase())
          : el[this.listItems.text].toLowerCase().includes(this.searchInput.toLowerCase())
      );
    }
  },

  // close dropdown if clicked elsewere
  mounted() {
    document.addEventListener('click', this.closeList);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeList);
  }
};
</script>
