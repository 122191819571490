import { getWeekNum } from '@/helpers/getWeekNum.js';

export default {
  computed: {
    offset() {
      return this.$store.getters['projectData/getOffsetDays'];
    },

    computedGrid() {
      // generate 0...n grid
      let grid = Array.from({ length: this.height }, (_, y) =>
        Array.from({ length: this.board.board_width }, (_, x) => ({ x, y }))
      ).flat();
      // increase coordinates by 1
      grid.forEach((el) => {
        el.x++;
        el.y++;
      });
      // inject activities
      grid.forEach((element) => {
        element.activity = this.activities.filter(
          (a) => a.activity_pos_x == element.x && a.activity_pos_y == element.y
        )[0];
      });
      if (this.type === 'rm') {
        this.addBackground(grid);
      }
      if (this.type === 'wv') {
        this.addWeekends(grid);
      }
      if (this.type === 'mpp') {
        this.addHolidays(grid);
      }
      return grid;
    },

    cssVars() {
      return `grid-template-columns: repeat(${this.board.board_width}, auto);
              grid-template-rows: repeat(${this.height}, auto)`;
    },

    lastAreaBoxBottomLine() {
      // get areas heights array
      const areaBorders = this.areas.map((el) => el.area_height);
      // get incremented array
      const progressiveBorders = areaBorders.map(
        (
          (s) => (a) =>
            (s += a)
        )(0)
      );
      // used to find out if box y is in progressiveBorders array, than bald it with bottom line class
      return progressiveBorders;
    },
    lastSubareasBoxBottomLine() {
      if (this.type !== 'rm') {
        let x = this.areas.map((elem) => elem.subareas);
        let subares_h_arr = [];
        // prettier-ignore
        for (let y of x) {
          for (let c of y) {
            subares_h_arr.push(c.subarea_h);
          }
        }
        // prettier-ignore
        const progressiveBorders = subares_h_arr.map(((s) => (a) =>(s += a))(0));
        return progressiveBorders;
      } else return [];
    }
  },

  methods: {
    addWeekends(grid) {
      grid.forEach((box) => {
        if (box.x === 6 - this.offset) {
          box.background = '#ededed';
        }
      });
    },

    addHolidays(grid) {
      const indexKW52 = this.board.board_subname.indexOf('KW 52');
      const indexKW01 = this.board.board_subname.indexOf('KW 01');
      if (indexKW52 >= 0) {
        grid.forEach((box) => {
          if (box.x == indexKW52 + 1) {
            box.background = '#ededed';
          }
        });
      }
      if (indexKW01 >= 0) {
        grid.forEach((box) => {
          if (box.x == indexKW01 + 1) {
            box.background = '#ededed';
          }
        });
      }
    },

    // right border for current week (mpp)
    currWeekDateMpp(x) {
      if (this.type === 'mpp' && this.board.actual === 'true') {
        const currentWeekN = getWeekNum(new Date());
        const weekNumbers = this.board.board_subname.map((week) => Number(week.slice(3, 5)));
        const currentWeekIndex = weekNumbers.indexOf(currentWeekN);
        return currentWeekIndex + 1 == x;
      }
    },

    // add background to risk matrix boxes
    addBackground(grid) {
      grid.forEach((box) => {
        const yellow = 'rgba(248, 229, 168, 0.747)';
        const grey = 'rgba(241, 241, 241, 0.712)';
        const red = 'rgba(247, 139, 139, 0.788)';
        switch (`${box.x}${box.y}`) {
          case '11':
          case '21':
          case '12':
          case '22':
          case '31':
          case '41':
          case '32':
          case '42':
          case '53':
          case '63':
          case '73':
          case '83':
          case '54':
          case '64':
          case '74':
          case '84':
          case '95':
          case '105':
          case '115':
          case '125':
          case '96':
          case '106':
          case '116':
          case '126':
            box.background = yellow;
            break;
          case '13':
          case '14':
          case '23':
          case '24':
          case '33':
          case '34':
          case '43':
          case '44':
          case '15':
          case '16':
          case '25':
          case '26':
          case '35':
          case '36':
          case '45':
          case '46':
          case '55':
          case '65':
          case '75':
          case '85':
          case '56':
          case '66':
          case '76':
          case '86':
            box.background = grey;
            break;
          case '51':
          case '61':
          case '71':
          case '81':
          case '91':
          case '101':
          case '111':
          case '211':
          case '52':
          case '62':
          case '72':
          case '82':
          case '92':
          case '102':
          case '112':
          case '121':
          case '122':
          case '93':
          case '94':
          case '103':
          case '104':
          case '113':
          case '114':
          case '123':
          case '124':
            box.background = red;
            break;
        }
      });
    }
  }
};
