<!-- prettier-ignore -->
<template>
  <div class="grid-container" :style="cssVars">
    <div
      class="grid-box"
      :class="[
        lastAreaBoxBottomLine.includes(box.y) ? 'bottomLine' : '' || 
        lastSubareasBoxBottomLine.includes(box.y) ? 'bottomLineSubarea' : '', 
        currWeekDateMpp(box.x) ? 'currentWeek' : '']"
      v-for="(box, index) in computedGrid"
      :key="index"
      :style="{ backgroundColor: box.background }"
      @dragover.prevent
      @dragenter.prevent
      @drop="dropActivity($event, box.x, box.y)"
      @click="handleClick($event, box.x, box.y, box.activity)"
    >    
      <div class="post-it-wrapper" v-if="box.activity">
        <StandardCard
          v-if="checkIfNotFiltered(box.activity.trade_id)"
          :activity="box.activity"
          :id="box.activity.activity_id"
          :type="type"
          :draggable="box.activity.activity_status !== 'done' && !box.activity.activity_marked && checkPermission(box.activity.trade_id)"
          @dragstart.native="pickupActivity($event, box.activity.activity_id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StandardCard from '@/components/grid/StandardCard.vue';

import gridInitMixin from '@/mixins/gridInitMixin.js';
import gridStoreMixin from '@/mixins/gridStoreMixin.js';
import gridDragDropMixin from '@/mixins/gridDragDropMixin.js';

export default {
  mixins: [gridInitMixin, gridStoreMixin, gridDragDropMixin],
  components: { StandardCard },
  props: {
    board: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    areas: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
.currentWeek {
  border-right: 6px solid #8b2121;
}
.bottomLine {
  border-bottom: 2px solid black;
}
.bottomLineSubarea {
  border-bottom: 1px solid lightslategrey;
}

/* used to hide selected element during drag operations */
.hide {
  transition: 0.01s;
  transform: translateX(-9999px);
}
</style>
