export default {
  computed: {
    horizontalScroll() {
      return `
        transform: translate(-${this.scrollX}px, 0px;
        width: ${this.board.board_width * 10}rem;
      `;
    },

    verticalScroll() {
      return `transform: translate(0px, -${this.scrollY}px;`;
    },

    // calculate css classes
    wrapperClass() {
      if (this.type === 'mpp' || this.type === 'wv') {
        return this.boardIndex === 0
          ? `wrapper-control-primary wrapper-control-primary__double`
          : `wrapper-control-secondary wrapper-control-secondary__double`;
      } else {
        return this.boardIndex === 0 ? `wrapper-control-primary` : `wrapper-control-secondary`;
      }
    },

    eckClass() {
      if (this.type === 'mpp' || this.type === 'wv') {
        return 'eck eck__double';
      } else {
        return 'eck';
      }
    }
  }
};
