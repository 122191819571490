<template>
  <div>
    <vc-donut
      foreground="#8a5c71"
      :size="14"
      unit="rem"
      :thickness="25"
      :sections="sections"
      :total="100"
      :auto-adjust-text-size="true"
    >
      <h1>{{ aez }}%</h1>
      <h3>AEZ</h3>
    </vc-donut>
  </div>
</template>

<script>
export default {
  props: {
    aez: {
      type: Number,
      default: ''
    }
  },
  data() {
    return {
      sections: [{ value: this.aez, color: '#000' }]
    };
  }
};
</script>
