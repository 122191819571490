import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state() {
    return {
      project: {},
      members: [],
      trades: [],
      colors: {},
      disruptions: [],
      sb: {},
      gpa: {},
      mpp: {},
      wv: {},
      ap: {},
      rm: {},
      kpi: {}
    };
  },
  actions,
  mutations,
  getters
};
