export default {
  // project
  io_shipProject(context, data) {
    context.commit('SET_PROJECT_DATA', data);
  },

  io_updateProject(context, data) {
    context.commit('UPDATE_PROJECT', data);
  },

  // members
  io_updateMember(context, data) {
    context.commit('UPDATE_MEMBER', data);
  },

  io_addMember(context, data) {
    context.commit('ADD_MEMBER', data);
  },

  io_deleteMember(context, data) {
    context.commit('DELETE_MEMBER', data);
  },

  // trades
  io_updateTrade(context, data) {
    context.commit('UPDATE_TRADE', data);
  },

  io_addTrade(context, data) {
    context.commit('ADD_TRADE', data);
  },

  io_deleteTrade(context, data) {
    context.commit('DELETE_TRADE', data);
  },

  // boards
  io_updateBoard(context, data) {
    context.commit('UPDATE_BOARD', data);
  },

  io_addBoard(context, data) {
    context.commit('ADD_BOARD', data);
  },

  io_deleteBoard(context, data) {
    context.commit('DELETE_BOARD', data);
  },

  // areas
  io_updateArea(context, data) {
    const { area, activities, type } = data;
    context.commit('UPDATE_AREA', { area, type });
    if (activities.length > 0) {
      context.commit('UPDATE_ACTIVITIES', { activities, type });
    }
  },

  io_addArea(context, data) {
    context.commit('ADD_AREA', data);
  },

  io_deleteArea(context, data) {
    const { areaId, activities, type } = data;
    context.commit('DELETE_AREA', { areaId, type });
    if (activities.length > 0) {
      context.commit('UPDATE_ACTIVITIES', { activities, type });
    }
  },

  // activities
  io_addActivity(context, data) {
    context.commit('ADD_ACTIVITY', data);
  },

  io_updateActivity(context, data) {
    context.commit('UPDATE_ACTIVITY', data);
  },

  io_deleteActivity(context, data) {
    context.commit('DELETE_ACTIVITY', data);
  },

  // ap
  io_updateAp(context, data) {
    context.commit('UPDATE_AP', data);
  },

  io_addAp(context, data) {
    context.commit('ADD_AP', data);
  },

  io_deleteAp(context, data) {
    context.commit('DELETE_AP', data);
  },

  // kpi
  io_updateKpi(context, data) {
    context.commit('UPDATE_KPI', data);
  }
};
