<!-- prettier-ignore -->
<template>
  <BaseDropdown
    :dropdownList="trades"
    :passedElement="selectedTrade"
    :listItems="{ color: 'hex_code', text: 'trade_name' }"
    @set-selection="selectTrade"
  >
    <template v-slot:selection>
      <span class="color-picker-shape" :style="{ background: selectedTrade.hex_code }"></span>{{ selectedTrade.trade_name }}
    </template>
  </BaseDropdown>
</template>

<script>
import BaseDropdown from '@/components/layout/BaseDropdown';

export default {
  components: { BaseDropdown },
  props: {
    tradeId: {
      type: String,
      default: false
    }
  },

  computed: {
    trades() {
      return this.$store.getters['projectData/getUserTrades'];
    },

    selectedTrade() {
      return this.tradeId
        ? this.trades.filter((t) => t.trade_id === this.tradeId)[0]
        : this.members[0];
    }
  },

  methods: {
    selectTrade(payload) {
      this.$emit('change-trade', payload.trade_id);
    }
  }
};
</script>
