<template>
  <section class="login">
    <form @submit.prevent="submitForm" class="form">
      <h1 class="heading-secondary heading-secondary__margin-bottom">Dein neues Passwort</h1>
      <div class="form__group">
        <input
          type="password"
          id="password"
          class="form__input"
          required="required"
          v-model="password"
        />
        <label for="password" class="form__label">Passwort</label>
      </div>
      <div class="form__group">
        <input
          type="password"
          id="password2"
          class="form__input"
          required="required"
          v-model="password2"
        />
        <label for="password2" class="form__label">Passwort wiederholen</label>
      </div>
      <div class="button">
        <button class="btn-base">Go &rarr;</button>
      </div>
    </form>
  </section>
</template>

<script>
export default {
  data() {
    return {
      password: '',
      password2: ''
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    token() {
      return this.$route.params.token;
    }
  },
  methods: {
    submitForm() {
      if (this.validatePassword()) {
        const payload = { id: this.id, token: this.token, password: this.password };
        this.$store.dispatch('auth/newpass', payload);
        this.$router.replace({ name: 'reset-next' });
      }
    },

    validatePassword() {
      if (this.password.length < 8) {
        this.$toast.info('Das Passwort ist zu kurz. Verwende mehr als 8 Zeichen.', {
          position: 'bottom-center',
          hideProgressBar: true
        });
      } else if (this.password != this.password2) {
        this.$toast.info('Die Passworter stimmen nicht überein', {
          position: 'bottom-center',
          hideProgressBar: true
        });
      } else {
        return true;
      }
    }
  }
};
</script>
