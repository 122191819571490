<script>
import { Line } from 'vue-chartjs';
import { Chart } from 'chart.js';

Chart.defaults.global.defaultFontFamily = 'Barlow';

export default {
  extends: Line,
  props: {
    msWeeks: {
      type: Array,
      required: true
    },
    soll: {
      type: Array,
      required: true
    },
    ist: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartData: {
        labels: this.msWeeks,
        datasets: [
          {
            label: 'Ist',
            borderColor: '#000000',
            backgroundColor: 'rgb(46, 46, 46)',
            data: this.ist,
            datalabels: {
              color: '#ffffff',
              // align: 'start',
              backgroundColor: '#000000',
              borderRadius: 4
            }
          },
          {
            label: 'Soll',
            borderColor: '#4d3140',
            backgroundColor: '#8a5c71',
            data: this.soll,
            datalabels: {
              color: '#000000',
              align: 'end'
            }
          }
        ]
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,

        title: {
          display: true,
          fontSize: 20,
          padding: 20,
          text: 'Meilensteine kummuliert',
          fontFamily: 'Barlow'
        },
        plugins: {
          datalabels: {
            font: {
              family: 'Barlow'
            }
          }
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 0,
            bottom: 10
          }
        },
        legend: {
          position: 'bottom',
          fontFamily: 'Barlow'
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                fontFamily: 'Barlow'
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontFamily: 'Barlow'
              }
            }
          ]
        }
      }
    };
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>
