export default {
  computed: {
    autoTextSize() {
      let contentTop = 0;
      let contentMid = 0;
      let contentBottom = 0;
      if (this.activity.activity_content_top) {
        contentTop = this.activity.activity_content_top.length;
      }
      if (this.activity.activity_content_mid) {
        contentMid = this.activity.activity_content_mid.length;
      }
      if (this.activity.activity_content_bottom) {
        contentBottom = this.activity.activity_content_bottom.length;
      }
      let textLength = contentTop + contentMid + contentBottom;

      if (textLength >= 80 && textLength <= 95) {
        return `0,9em`;
      } else if (textLength > 95 && textLength <= 130) {
        return `0.8em`;
      } else if (textLength > 130 && textLength <= 165) {
        return `0.75em`;
      } else if (textLength > 165) {
        return `0.7em`;
      }
    }
  }
};
