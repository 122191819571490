/* socket io module to handle and document outgoing socket requests  */
export default {
  namespaced: true,
  state: {
    io: {}
  },

  actions: {
    requestProject(context, projectId) {
      context.state.io.emit('requestProject', { projectId });
    },

    updateProject(context, payload) {
      payload.projectId = context.rootState.projectData.project.project_id;
      context.state.io.emit('updateProject', payload);
    },

    updateMember(context, payload) {
      payload.projectId = context.rootState.projectData.project.project_id;
      context.state.io.emit('updateMember', payload);
    },

    updateTrade(context, payload) {
      payload.projectId = context.rootState.projectData.project.project_id;
      payload.trade.projectId = context.rootState.projectData.project.project_id;
      context.state.io.emit('updateTrade', payload);
    },

    updateBoard(context, payload) {
      payload.projectId = context.rootState.projectData.project.project_id;
      context.state.io.emit('updateBoard', payload);
    },

    updateArea(context, payload) {
      payload.projectId = context.rootState.projectData.project.project_id;
      context.state.io.emit('updateArea', payload);
    },

    updateActivity(context, payload) {
      payload.projectId = context.rootState.projectData.project.project_id;
      context.state.io.emit('updateActivity', payload);
    },

    updateAp(context, payload) {
      payload.projectId = context.rootState.projectData.project.project_id;
      context.state.io.emit('updateAp', payload);
    },

    updateKpi(context) {
      // clear current kpis
      context.commit('projectData/CLEAR_KPI', null, { root: true });
      const payload = {};
      payload.projectId = context.rootState.projectData.project.project_id;
      payload.action = 'updateKpi';
      context.state.io.emit('updateProject', payload);
    }
  },

  // socket io instance is passed from ProjectPage wrapper component
  mutations: {
    SET_SOCKET(state, socket) {
      state.io = socket;
    }
  }
};
