<template>
  <div>
    <div class="toggle-control-wrapping">
      <!-- toggle -->
      <div class="toggle-wrapping">
        <input
          id="toggle-on"
          class="toggle toggle-left"
          name="toggle"
          value="left"
          v-model="currentTab"
          type="radio"
          checked
        />
        <label for="toggle-on" class="btn-toggle">Team</label>
        <input
          id="toggle-off"
          class="toggle toggle-right"
          name="toggle"
          value="right"
          v-model="currentTab"
          type="radio"
        />
        <label for="toggle-off" class="btn-toggle">Funktionen</label>
      </div>
      <!-- add buttons -->
      <div
        v-if="currentTab === 'left' && perm"
        class="add-control add-control__team no-print"
        @click="addMember"
      >
        Neues Mitglied
      </div>
      <div
        v-if="currentTab === 'right' && perm"
        class="add-control add-control__team no-print"
        @click="addTrade"
      >
        Neue Funktion
      </div>
    </div>

    <!-- tables -->
    <transition :name="currentTab" mode="out-in">
      <component
        :is="currentTab"
        :perm="perm"
        @edit-member="editMember"
        @edit-trade="editTrade"
      ></component>
    </transition>

    <!-- modals -->
    <transition name="pages" mode="out-in">
      <router-view></router-view>
    </transition>

    <!-- print settings -->
    <component is="style">
      {{ printSettings }}
    </component>
  </div>
</template>

<script>
import left from '@/pages/tables/TableMembers';
import right from '@/pages/tables/TableTrades';

export default {
  components: { left, right },
  props: {
    perm: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentTab: 'left'
    };
  },

  methods: {
    addMember() {
      this.$router.push({ name: 'teamMember', params: { id: 'new' } });
    },

    editMember(memberId) {
      this.$router.push({ name: 'teamMember', params: { id: memberId } });
    },

    addTrade() {
      this.$router.push({ name: 'teamTrade', params: { id: 'new' } });
    },

    editTrade(tradeId) {
      this.$router.push({ name: 'teamTrade', params: { id: tradeId } });
    }
  },

  computed: {
    printSettings() {
      const printVars = `@media print { @page { size: 420mm 594mm; } }`;
      return printVars;
    }
  }
};
</script>
