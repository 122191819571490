<template>
  <div>
    <div class="moin">Moin, {{ username }}</div>
    <div class="moin-secondary">
      <p>Willkommen zurück!</p>
      <p>Hier findest Du eine Übersicht Deiner Projekte</p>
    </div>
    <div class="grid">
      <router-link v-if="perm" class="grid__container-new" :to="{ name: 'newProject' }">
        <div class="plus-project">&#43;</div>
        <div class="plus-project-text">Neues Projekt</div>
      </router-link>
      <div
        v-for="project in projects"
        :key="project.project_id"
        @click="chooseProject(project.project_id)"
        class="grid__container"
      >
        <div class="projects-cards">
          <div class="projects-cards__name">
            {{ project.project_name }}
          </div>
          <div class="projects-cards__adress">
            {{ project.project_city }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // fetch all projects before mount
  beforeMount() {
    this.$store.dispatch('projects/fetchAllProjects');
  },

  computed: {
    username() {
      return this.$store.state.auth.user.user_firstname;
    },
    projects() {
      return this.$store.state.projects.projects;
    },
    perm() {
      return this.$store.getters['auth/getUserPermission'];
    }
  },

  methods: {
    chooseProject(projectId) {
      // write selected project id in Local Storage
      this.$store.commit('projects/STORE_PROJECT_ID', projectId);
      
      // Check license
      const selectedProject = this.projects.filter((p) => p.project_id === projectId)[0];
      const projectNamePrettyPrint = selectedProject.project_name.toLowerCase().split(' ').join('_');
      const userType = this.$store.state.auth.user.user_type;
      if (userType == 'power' || selectedProject.project_license !== null) {
        if (selectedProject.project_license == null) {
          this.$toast.info('Das Projekt hat keine Lizenz', {
            position: 'bottom-center',
            hideProgressBar: true,
            closeButton: false
          });
        }
        this.$router.push({
          name: 'projectPage',
          params: { projectName: projectNamePrettyPrint }
        });
      } else {
         this.$router.push({name: 'noLicense'});
      }
    }
  }
};
</script>
