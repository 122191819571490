<!-- prettier-ignore -->
<template>
  <div>
    <!-- filter -->
    <div class="eck-filter" @click="openFilterView()">
      <base-icon class="filter-icon" icon-name="filter" viewBox="0 0 32 32">
        <IconFilter />
      </base-icon>
    </div>
    <!-- header -->
    <div 
        :style="horizontalScroll"
        :class="currentDate ? 'currentDate':''"
         class="horizontal-control horizontal-control__double" 
    >
      <div class="horizontal-header-wrapper">
        <div class="horizontal-header">
          <h2>{{ board.board_name }}</h2>
        </div>
      </div>
      <!-- subheader -->
      <div class="horizontal-subheader-wrapper">
        <div class="horizontal-subheader" v-for="(text, index) in boardSubheader" :key="index">
          <div>{{ text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconFilter from '@/components/icons/IconFilter';
import { getWeekNum } from '@/helpers/getWeekNum.js';

export default {
  components: { IconFilter },
  props: {
    board: {
      type: Object,
      required: true
    },
    horizontalScroll: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    openFilterView() {
      this.$emit('openFilterView');
    }
  },

  computed: {
    boardSubheader() {
      return this.type === 'wv'
        ? this.$store.getters[`projectData/getBoardSubheader`](this.board.board_id)
        : this.board.board_subname;
    },

    currentDate() {
      // background color of current month (mpp)
      if (this.type === 'mpp') {
        if (this.board.actual === 'true') {
          return true;
        }
      } else {
        // background color of current week (wv)
        const boardWeekNum = this.board.board_n % 52;
        const currentWeekN = getWeekNum(new Date());
        if (boardWeekNum === currentWeekN) {
          return true;
        }
      }
    }
  }
};
</script>

<style>
.currentDate {
  background-color: #8b2121;
  color: white;
}
</style>
