// vue base
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

// axios
import axios from 'axios';
import VueAxios from 'vue-axios';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.use(VueAxios, axios);

// toaster
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
Vue.use(Toast, { maxToasts: 1 });

// donut chart
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
Vue.use(Donut);

// base components
import BaseIcon from './components/layout/BaseIcon.vue';
Vue.component('base-icon', BaseIcon);

Vue.config.productionTip = false;

// error handling
if (process.env.NODE_ENV != 'development')
  Vue.config.errorHandler = () => {
    router.push('/', () => {
      Vue.$toast.info('Oops.. etwas ist falsch gelaufen und Du wurdest hier umgeleitet :)', {
        position: 'bottom-center',
        hideProgressBar: true,
        closeButton: false
      });
    });
  };

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
