<template>
  <div>
    <div class="charts-wrapper-line-2">
      <div class="chart-wrapper chart-wrapper__trades-aez">
        <TradesAez :height="null" :width="null" :trades="tradesList" :aez="aezListLast" />
      </div>

      <div class="charts-wrapper-cards">
        <div class="last-week-wrapper-line">
          <div class="charts-wrapper-cards">
            <div class="chart-wrapper chart-wrapper__card-aez">
              <CardAez :aez="aezLast" />
            </div>
            <div class="chart-wrapper chart-wrapper__card-mpp">
              <CardMs :ist="msLastIst" :soll="msLastSoll" />
            </div>
          </div>
          <div class="chart-wrapper chart-wrapper__week-aez">
            <WeekAez :kwLast="kwLast" :erledigt="erledigtLast" :nichtErledigt="nichtErledigtLast" />
          </div>
          <div class="chart-wrapper chart-wrapper__doughnut">
            <DisruptionDoughnut
              :colors="disrColors"
              :context="disrContext"
              :values="disrValuesLast"
            />
          </div>
        </div>

        <div class="chart-wrapper chart-wrapper__disruption-trades">
          <DisruptionTrades :disrDataset="disrDatasetLast" :tradesList="tradesList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kpiDataMixin from '@/mixins/kpiDataMixin';

import DisruptionDoughnut from './charts/DisruptionDoughnut';
import WeekAez from './charts/WeekAez';
import TradesAez from './charts/TradesAez';
import DisruptionTrades from './charts/DisruptionTrades.vue';
import MilestonesFlow from './charts/MilestonesFlow.vue';
import CardAez from './charts/CardAez.vue';
import CardMs from './charts/CardMs.vue';

export default {
  components: {
    DisruptionDoughnut,
    WeekAez,
    TradesAez,
    DisruptionTrades,
    MilestonesFlow,
    CardAez,
    CardMs
  },
  mixins: [kpiDataMixin]
};
</script>
