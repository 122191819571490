export default {
  SET_PROJECT_DATA(state, payload) {
    Object.assign(state, payload);
  },

  // project
  UPDATE_PROJECT(state, paylaod) {
    Object.assign(state.project, paylaod);
  },

  // members
  UPDATE_MEMBER(state, payload) {
    const memberIndex = state.members.findIndex((member) => member.member_id === payload.member_id);
    state.members.splice(memberIndex, 1, payload);
  },

  ADD_MEMBER(state, payload) {
    state.members.push(payload);
  },

  DELETE_MEMBER(state, payload) {
    const memberIndex = state.members.findIndex((member) => member.member_id === payload.member_id);
    state.members.splice(memberIndex, 1);
  },

  // trades
  UPDATE_TRADE(state, payload) {
    const tradeIndex = state.trades.findIndex((trade) => trade.trade_id === payload.trade_id);
    state.trades.splice(tradeIndex, 1, payload);
  },

  ADD_TRADE(state, payload) {
    state.trades.push(payload);
  },

  DELETE_TRADE(state, payload) {
    const tradeIndex = state.trades.findIndex((trade) => trade.trade_id === payload.trade_id);
    state.trades.splice(tradeIndex, 1);
  },

  // boards
  UPDATE_BOARD(state, payload) {
    const boardIndex = state[payload.type].boards.findIndex(
      (board) => board.board_id === payload.board.board_id
    );
    state[payload.type].boards.splice(boardIndex, 1, payload.board);
  },

  ADD_BOARD(state, payload) {
    state[payload.type].boards.push(payload.board);
    // toggle scroll to load board header properly
    window.scrollBy({ top: 1, left: 1 });
    window.scrollBy({ top: -1, left: -1 });
  },

  DELETE_BOARD(state, payload) {
    const { type, boardId } = payload;
    state[type].boards = state[type].boards.filter((b) => b.board_id != boardId);
  },

  // areas
  UPDATE_AREA(state, payload) {
    const areaIndex = state[payload.type].areas.findIndex(
      (area) => area.area_id === payload.area.area_id
    );
    state[payload.type].areas.splice(areaIndex, 1, payload.area);
  },

  ADD_AREA(state, payload) {
    state[payload.type].areas.push(payload.area);
  },

  DELETE_AREA(state, payload) {
    const { type, areaId } = payload;
    state[type].areas = state[type].areas.filter((b) => b.area_id != areaId);
  },

  // activities
  ADD_ACTIVITY(state, payload) {
    state[payload.type].activities.push(payload.activity);
  },

  UPDATE_ACTIVITY(state, payload) {
    const activityIndex = state[payload.type].activities.findIndex(
      (activity) => activity.activity_id === payload.activity.activity_id
    );
    state[payload.type].activities.splice(activityIndex, 1, payload.activity);
  },

  DELETE_ACTIVITY(state, payload) {
    const { type, activityId } = payload;
    state[type].activities = state[type].activities.filter((b) => b.activity_id != activityId);
  },

  UPDATE_ACTIVITIES(state, payload) {
    Object.assign(state[payload.type].activities, payload.activities);
  },

  // AP
  UPDATE_AP(state, payload) {
    const apIndex = state.ap.findIndex((ap) => ap.action_id === payload.action_id);
    state.ap.splice(apIndex, 1, payload);
  },

  ADD_AP(state, payload) {
    state.ap.push(payload);
  },

  DELETE_AP(state, payload) {
    const { actionId } = payload;
    state.ap = state.ap.filter((a) => a.action_id !== actionId);
  },

  // KPI
  CLEAR_KPI(state) {
    state.kpi = { loaded: false };
  },

  UPDATE_KPI(state, payload) {
    Object.assign(state.kpi, payload);
  }
};
