<template>
  <div class="table-scroll">
    <table class="fixed_header fixed_header__poject-trades">
      <thead class="table-head table-team">
        <tr>
          <th
            @click="sort('color_name')"
            class="table-simplean"
            :class="{ sorted: this.currentSort == 'color_name' }"
          >
            Farbe
          </th>
          <th
            @click="sort('trade_name')"
            class="table-simplean"
            :class="{ sorted: this.currentSort == 'trade_name' }"
          >
            Funktion
          </th>
        </tr>
      </thead>
      <tbody class="tbody-shadow">
        <tr
          class="table-team-data"
          v-for="trade in trades"
          :key="trade.trade_id"
          @click="edit(trade.trade_id)"
        >
          <td class="table-simplean">
            <span
              class="color-picker-shape color-picker-shape__table"
              :style="{ background: trade.hex_code }"
            ></span>
          </td>
          <td class="table-simplean">{{ trade.trade_name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    perm: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentSort: 'color_name',
      currentSortDir: 'asc'
    };
  },

  computed: {
    trades() {
      return this.$store.state.projectData.trades.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir == 'desc') modifier = -1;
        // sorting
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  },

  methods: {
    sort(sortField) {
      //if sortField == current sort, reverse
      if (sortField === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = sortField;
    },

    edit(id) {
      this.perm && this.$emit('edit-trade', id);
    }
  }
};
</script>
