import Vue from 'vue';
import Vuex from 'vuex';
// import { createLogger } from 'vuex';

Vue.use(Vuex);

//import store modules
import auth from './auth.js';
import projects from './projects.js';
import projectData from './projectData/index.js';
import socket from './socket.js';
import temp from './temp.js';

const store = new Vuex.Store({
  // plugins: [createLogger()],
  modules: {
    auth,
    projects,
    projectData,
    socket,
    temp
  }
});

export default store;
