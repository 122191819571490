export default {
  /* handle ghost element trashing */
  mounted() {
    document.addEventListener('dragend', this.removeGhosts, false);
  },
  beforeDestroy() {
    document.removeEventListener('dragend', this.removeGhosts);
  },

  methods: {
    /* start drag and drop cycle */
    pickupActivity(e, activityId) {
      /* 1. Find all selected activities */
      const selectedArray = this.$store.state.projectData[this.type].activities.filter(
        (a) => a.selected
      );
      /* 2. Map x_v to x */
      const vm = this.$store.getters['projectData/getVirtualMap'](this.type);
      selectedArray.forEach((el) => {
        el.x_v = vm.filter(
          (v) => v.x === el.activity_pos_x && v.y === el.activity_pos_y && v.id === el.board_id
        )[0].x_v;
      });
      /* 3. Check if selected activity is picked */
      if (
        selectedArray.length > 1 &&
        selectedArray.filter((el) => el.activity_id === activityId).length > 0
      ) {
        /* if yes, drag multiple items */
        const draggedArray = selectedArray.map(
          ({ activity_id, activity_pos_x, activity_pos_y, x_v }) => {
            return {
              activity_id,
              activity_pos_x,
              x_v,
              activity_pos_y,
              lead: activity_id === activityId ? true : false
            };
          }
        );
        this.dragMultiple(e, draggedArray);
      } else {
        /* if no, drag one item */
        this.dragOne(e, activityId);
      }
    },

    dragMultiple(e, draggedArray) {
      e.dataTransfer.effectAllowed = 'copyMove';

      let activitiesSet = document.createElement('div');
      let coordinatesSet = [];

      draggedArray.forEach((el) => {
        const targetElement = document.getElementById(el.activity_id);
        /* write coordinate Set */
        const yPoint = window.scrollY + targetElement.getBoundingClientRect().top;
        const xPoint = window.scrollX + targetElement.getBoundingClientRect().left;
        coordinatesSet.push({ xPoint, yPoint });

        /* hide elements if move */
        if (!e.altKey) {
          targetElement.parentElement.classList.add('hide');
        }

        /* toggle shadow and copy activity as ghost */
        targetElement.classList.remove('activity-selected');
        const ghost = targetElement.parentElement.cloneNode(true);
        targetElement.classList.add('activity-selected');
        ghost.classList.add('ghost');
        /* arrange activity  */
        ghost.style.position = 'absolute';
        ghost.style.top = `${yPoint - 50000}px`;
        ghost.style.left = `${xPoint - 50000}px`;
        /* append to set */
        activitiesSet.appendChild(ghost);
      });

      /* calculate pointer */
      function getMin(data, point) {
        return data.reduce((min, p) => (p[point] < min ? p[point] : min), data[0][point]);
      }
      const minX = getMin(coordinatesSet, 'xPoint');
      const minY = getMin(coordinatesSet, 'yPoint');
      /* subtract min coordinates from pointer position */
      const xOffset = e.pageX - minX;
      const yOffset = e.pageY - minY;

      /* set ghost element*/
      document.body.prepend(activitiesSet);
      e.dataTransfer.setDragImage(activitiesSet, xOffset, yOffset);

      /* temp save dragged array*/
      this.$store.dispatch('temp/setDraggedArray', draggedArray);
    },

    dragOne(e, activityId) {
      e.dataTransfer.setData('activityId', activityId);
      e.dataTransfer.effectAllowed = 'copyMove';

      // check if copy mode:
      const status = this.getActivity(activityId).activity_status;
      const copyMode = e.altKey || status === 'fail';
      if (!copyMode) {
        /* if not, hide original element */
        e.target.classList.add('hide');
      }

      // create ghost
      const postIt = e.target.parentElement;
      const ghost = postIt.cloneNode(true);

      ghost.style.position = 'absolute';
      ghost.style.top = '0px';
      ghost.style.left = '-100px';
      ghost.classList.add('ghost');

      // rotate ghost
      if (e.target.classList.contains('post-it-romb')) {
        let inner = ghost.getElementsByClassName('post-it-romb')[0];
        inner.style.transform = 'rotate(47deg)';
        e.dataTransfer.setDragImage(ghost, 40, 60);
      } else {
        let inner = ghost.getElementsByClassName('post-it')[0];
        inner.style.transform = 'rotate(2deg)';
        e.dataTransfer.setDragImage(ghost, 50, 50);
      }
      document.body.appendChild(ghost);
    },

    dropActivity(e, toX, toY) {
      if (e.dataTransfer.getData('activityId')) {
        this.dropOne(e, toX, toY);
      } else {
        this.dropMultiple(e, toX, toY);
      }
    },

    dropOne(e, toX, toY) {
      // check if there is any activity
      if (
        this.activities.find(
          (a) =>
            a.activity_pos_x == toX && a.activity_pos_y == toY && a.board_id == this.board.board_id
        )
      ) {
        console.log('There is something here!');
      } else {
        const activityId = e.dataTransfer.getData('activityId');
        this.moveActivity(e, activityId, toX, toY);
      }
    },

    dropMultiple(e, toX, toY) {
      /* get virtual  map */
      const vm = this.$store.getters['projectData/getVirtualMap'](this.type);
      /* map target field to virtial map */
      const targetField = vm.filter(
        (v_el) => v_el.id === this.board.board_id && v_el.x === toX && v_el.y === toY
      )[0];
      /* get dragged array */
      const draggedArray = this.$store.state.temp.draggedArray;
      /* get lead starting coordinates */
      const draggedLeadX_V = draggedArray.filter((el) => el.lead === true)[0].x_v;
      const draggedLeadY = draggedArray.filter((el) => el.lead === true)[0].activity_pos_y;
      /* map target array */
      try {
        const targetArray = draggedArray.map((a) => {
          /* 1) calculate x_v and y offsets */
          const x_vOffset = a.x_v - draggedLeadX_V;
          const yOffset = a.activity_pos_y - draggedLeadY;
          /* 2) calculate drop coordinates */
          const x_v = targetField.x_v + x_vOffset;
          const y = targetField.y + yOffset;
          /* 3) transform x_v to x and get board id */
          const backProp = vm.filter((el) => el.x_v === x_v && el.y === y)[0];
          /* 4) validate, that target field is empty */
          if (backProp.occupied) {
            throw new Error('Field is occupied!');
          }
          /* 5) return mapped coordinates */
          return {
            activity_id: a.activity_id,
            x: backProp.x,
            y,
            boardId: backProp.id
          };
        });
        /* move activities */
        targetArray.forEach((el) => {
          this.moveActivity(e, el.activity_id, el.x, el.y, el.boardId);
        });
      } catch (error) {
        console.log(error.message);
      }
      /* remove dragged array*/
      this.$store.dispatch('temp/clearDraggedArray');
      /* remove ghosts */
      this.removeGhosts();
    },

    moveActivity(e, id, toX, toY, boardId) {
      const activityToMove = this.getActivity(id);
      const payload = { type: this.type };
      const targedBoardId = boardId ? boardId : this.board.board_id;
      // copy or move
      if (e.altKey || activityToMove.activity_status === 'fail') {
        // compose payload
        payload.action = 'copy';
        payload.activity = { ...activityToMove };
        payload.activity.board_id = targedBoardId;
        payload.activity.activity_pos_x = toX;
        payload.activity.activity_pos_y = toY;
        payload.activity.activity_status = 'active';
      } else {
        // change activity props
        payload.action = 'update';
        payload.activity = activityToMove;
        activityToMove.board_id = targedBoardId;
        activityToMove.activity_pos_x = toX;
        activityToMove.activity_pos_y = toY;
      }
      this.$store.dispatch('socket/updateActivity', payload);
    },

    /***********        helpers           ***********/
    getActivity(id) {
      return this.$store.state.projectData[this.type].activities.filter(
        (a) => a.activity_id === id
      )[0];
    },

    getBoardId(n) {
      return this.$store.state.projectData[this.type].boards.filter((b) => b.board_n === n)[0]
        .board_id;
    },

    getBoardWidth(id) {
      return this.$store.state.projectData[this.type].boards.filter((b) => b.board_id === id)[0]
        .board_width;
    },

    removeGhosts() {
      let hiddenEl = document.getElementsByClassName('hide');
      while (hiddenEl[0]) {
        hiddenEl[0].classList.remove('hide');
      }
      let ghosts = document.getElementsByClassName('ghost');
      while (ghosts[0]) {
        ghosts[0].parentNode.removeChild(ghosts[0]);
      }
    }
  }
};
