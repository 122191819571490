export default (type) => {
  return {
    props: {
      perm: {
        type: Boolean,
        default: false
      }
    },

    // HANDLE SCROLL HEADER ==================================
    data() {
      return {
        scrollX: null,
        scrollY: null
      };
    },
    created() {
      window.addEventListener('scroll', this.updateScroll);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.updateScroll);
    },
    methods: {
      updateScroll() {
        this.scrollX = window.scrollX;
        this.scrollY = window.scrollY;
      }
    },
    // HANDLE SCROLL HEADER ====================================

    computed: {
      boards() {
        return this.$store.getters[`projectData/getBoards`](type);
      },
      activities() {
        return this.$store.state.projectData[type].activities;
      },
      areas() {
        return this.$store.getters[`projectData/getAreas`](type);
      },
      height() {
        return this.$store.getters[`projectData/getHeight`](type);
      },

      width() {
        return this.$store.getters[`projectData/getWidth`](type);
      },
      lastBoardId() {
        return this.$store.getters[`projectData/getLastBoardId`](type);
      },
      printSettings() {
        let printVars;
        if (this.width && this.height) {
          const w = this.width * 28;
          const h = (this.height + 1.5) * 28;
          printVars = `@media print { @page { size: ${w}mm ${h}mm; } }`;
        }
        return printVars;
      }
    }
  };
};
