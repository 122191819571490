export default {
  namespaced: true,
  state() {
    return {
      selectedActivityId: '',
      draggedArray: null
    };
  },

  actions: {
    io_setSelectedActivityId(context, data) {
      context.commit('SET_SELECTED_ID', data);
    },

    setDraggedArray({ commit }, payload) {
      commit('SET_DRAGGED_ARRAY', payload);
    },

    clearDraggedArray({ commit }) {
      commit('CLEAR_DRAGGED_ARRAY');
    }
  },

  mutations: {
    SET_SELECTED_ID(state, payload) {
      state.selectedActivityId = payload;
    },
    CLEAR_SELECTION(state) {
      state.selectedActivityId = null;
    },
    SET_DRAGGED_ARRAY(state, payload) {
      state.draggedArray = payload;
    },
    CLEAR_DRAGGED_ARRAY(state) {
      state.draggedArray = null;
    }
  }
};
