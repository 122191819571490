<template>
  <div>
    <div class="grid-project-page">
      <router-link class="grid-project-page__container" :to="{ name: 'gpa' }">
        <div class="feature-box">
          <svg class="feature-box__icon">
            <use xlink:href="@/assets/img/share.svg#icon-share"></use>
          </svg>
          <h3 class="grid-project-card">Gesamtprozessanalyse</h3>
        </div>
      </router-link>
      <router-link class="grid-project-page__container" :to="{ name: 'mpp' }">
        <div class="feature-box">
          <svg class="feature-box__icon">
            <use xlink:href="@/assets/img/flag.svg#icon-flag"></use>
          </svg>
          <h3 class="grid-project-card">Meilenstein- und Phasenplan</h3>
        </div>
      </router-link>
      <router-link class="grid-project-page__container" :to="{ name: 'wv' }">
        <div class="feature-box">
          <svg class="feature-box__icon">
            <use xlink:href="@/assets/img/calendar-alt-fill.svg#icon-calendar-alt-fill"></use>
          </svg>
          <h3 class="grid-project-card">Wochenvorschau</h3>
        </div>
      </router-link>
      <router-link class="grid-project-page__container" :to="{ name: 'team' }">
        <div class="feature-box">
          <svg class="feature-box__icon">
            <use xlink:href="@/assets/img/users.svg#icon-users"></use>
          </svg>
          <h3 class="grid-project-card">Projektbeteiligte</h3>
        </div>
      </router-link>
      <router-link class="grid-project-page__container" :to="{ name: 'sb' }">
        <div class="feature-box">
          <svg class="feature-box__icon">
            <use xlink:href="@/assets/img/light-bulb.svg#icon-light-bulb"></use>
          </svg>
          <h3 class="grid-project-card">Sandkasten</h3>
        </div>
      </router-link>
      <router-link class="grid-project-page__container" :to="{ name: 'kpi' }">
        <div class="feature-box">
          <svg class="feature-box__icon">
            <use xlink:href="@/assets/img/stats-bars.svg#icon-stats-bars"></use>
          </svg>
          <h3 class="grid-project-card">Verbesserung</h3>
        </div>
      </router-link>
      <router-link class="grid-project-page__container" :to="{ name: 'ap' }">
        <div class="feature-box">
          <svg class="feature-box__icon">
            <use xlink:href="@/assets/img/list.svg#icon-list"></use>
          </svg>
          <h3 class="grid-project-card">Aktionsplan</h3>
        </div>
      </router-link>
      <router-link class="grid-project-page__container" :to="{ name: 'rm' }">
        <div class="feature-box">
          <svg class="feature-box__icon">
            <use xlink:href="@/assets/img/border-inner.svg#icon-border-inner"></use>
          </svg>
          <h3 class="grid-project-card">Risiken</h3>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    perm: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    projectMeta() {
      return this.$store.state.projectData.project;
    }
  }
};
</script>
