<!-- prettier-ignore -->
<template>
  <BaseDropdown
    :dropdownList="colors"
    :passedElement="selectedColor"
    :listItems="{ color: 'hex_code', text: 'color_name' }"
     @set-selection="changeColor"
  >
    <template v-slot:selection>
      <span class="color-picker-shape" :style="{ background: selectedColor.hex_code }"></span>{{ selectedColor.color_name }}
    </template>
  </BaseDropdown>
</template>

<script>
import BaseDropdown from '@/components/layout/BaseDropdown';

export default {
  components: { BaseDropdown },
  props: {
    colorId: {
      type: String,
      required: false
    },
    set: {
      type: String,
      default: 'members'
    }
  },

  computed: {
    colors() {
      return this.$store.state.projectData.colors[this.set];
    },

    selectedColor() {
      if (this.colorId) {
        return this.colors.filter((col) => col.color_id === this.colorId)[0];
      } else {
        return this.colors[0];
      }
    }
  },
  methods: {
    changeColor(payload) {
      this.$emit('change-color', payload.color_id);
    }
  }
};
</script>
