<template>
  <div class="table-scroll">
    <table class="fixed_header fixed_header__members">
      <thead class="table-head table-team">
        <tr>
          <th
            @click="sort('user_firstname')"
            class="table-simplean"
            :class="{ sorted: this.currentSort == 'user_firstname' }"
          >
            Vorname
          </th>
          <th
            @click="sort('user_lastname')"
            class="table-simplean"
            :class="{ sorted: this.currentSort == 'user_lastname' }"
          >
            Name
          </th>
          <th
            @click="sort('user_company')"
            class="table-simplean"
            :class="{ sorted: this.currentSort == 'user_company' }"
          >
            Firma
          </th>
          <th
            @click="sort('user_email')"
            class="table-simplean"
            :class="{ sorted: this.currentSort == 'user_email' }"
          >
            E-Mail
          </th>
          <th
            @click="sort('user_phone')"
            class="table-simplean"
            :class="{ sorted: this.currentSort == 'user_phone' }"
          >
            Telefon
          </th>
          <th
            @click="sort('member_function')"
            class="table-simplean"
            :class="{ sorted: this.currentSort == 'member_function' }"
          >
            Funktion
          </th>
          <th class="table-simplean"><div></div></th>
        </tr>
      </thead>
      <tbody class="tbody-shadow">
        <tr
          class="table-team-data"
          v-for="member in members"
          :key="member.member_id"
          @click="edit(member.member_id)"
        >
          <td class="table-simplean">{{ member.user_firstname }}</td>
          <td class="table-simplean">{{ member.user_lastname }}</td>
          <td class="table-simplean">{{ member.user_company }}</td>
          <td class="table-simplean">
            <a class="table-mailto" :href="'mailto:' + member.user_email">
              {{ member.user_email }}
            </a>
          </td>
          <td class="table-simplean">{{ member.user_phone }}</td>
          <td class="table-simplean">
            <div v-html="tradesList(member.trade_id)"></div>
          </td>
          <!-- Admin Symbol -->
          <td class="table-simplean">
            <div v-if="member.member_type == 'admin'">
              <div class="member-admin-symbol"></div>
              <base-icon class="icon-admin"><IconAdmin /></base-icon>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import IconAdmin from '@/components/icons/IconAdmin';

export default {
  components: { IconAdmin },
  props: {
    perm: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentSort: 'user_lastname',
      currentSortDir: 'asc'
    };
  },

  computed: {
    members() {
      return this.$store.state.projectData.members.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir == 'desc') modifier = -1;
        // sorting
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  },
  methods: {
    tradesList(tradeIdArray) {
      const trades = this.$store.state.projectData.trades;
      const t = trades
        .filter((t) => tradeIdArray.includes(t.trade_id))
        .map((t) => {
          return `
          <div>
            <span class="color-picker-shape" style="background:${t.hex_code}"></span>
            ${t.trade_name}
          </div>`;
        })
        .join('');

      return t;
    },

    sort(sortField) {
      //if sortField == current sort, reverse
      if (sortField === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = sortField;
    },

    edit(id) {
      this.perm && this.$emit('edit-member', id);
    }
  }
};
</script>
