<!-- prettier-ignore -->
<template>
  <BaseDropdown
    :dropdownList="members"
    :passedElement="selectedMember"
    :listItems="{ text: 'user_firstname', text2: 'user_lastname' }"
    :metadata="{ disabled: disabled }"
    @set-selection="selectMember"
  >
    <template v-slot:selection>
      <!-- <span class="color-picker-shape" :style="{ background: selectedMember.hex_code }"></span> -->
      {{ selectedMember.user_firstname + ' ' + selectedMember.user_lastname }}
    </template>
  </BaseDropdown>
</template>

<script>
import BaseDropdown from '@/components/layout/BaseDropdown';

export default {
  components: { BaseDropdown },

  props: {
    memberId: {
      type: String,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    members() {
      return this.$store.state.projectData.members;
    },

    selectedMember() {
      if (this.memberId) {
        return this.members.filter((m) => m.member_id === this.memberId)[0];
      } else {
        return { user_firstname: 'Auswählen', user_lastname: '...' };
      }
    }
  },

  methods: {
    selectMember(payload) {
      this.$emit('change-member', payload.member_id);
    }
  }
};
</script>
