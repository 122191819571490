export default {
  computed: {
    // AEZ Circle
    aezGesamt() {
      return Math.round(this.$store.state.projectData.kpi.aezGesamt.aez * 100);
    },
    aezLast() {
      return Math.round(this.$store.state.projectData.kpi.aezLast.aez * 100);
    },

    // AEZ pro Gewerk
    tradesList() {
      return this.$store.state.projectData.kpi.aezGewerkGesamt.map((el) => el.gewerk);
    },
    aezListGesamt() {
      return this.$store.state.projectData.kpi.aezGewerkGesamt.map((el) =>
        Math.round(el.aez * 100)
      );
    },
    aezListLast() {
      return this.$store.state.projectData.kpi.aezGewerkLast.map((el) => Math.round(el.aez * 100));
    },

    // AEZ pro Woche
    kwList() {
      return this.$store.state.projectData.kpi.aezWeek.map((el) => el.kw);
    },
    kwLast() {
      return this.$store.state.projectData.kpi.aezWeek.map((el) => el.kw).slice(-1);
    },
    erledigtList() {
      return this.$store.state.projectData.kpi.aezWeek.map((el) => el.eingehalten);
    },
    erledigtLast() {
      return this.$store.state.projectData.kpi.aezWeek.map((el) => el.eingehalten).slice(-1);
    },
    nichtErledigt() {
      return this.$store.state.projectData.kpi.aezWeek.map((el) => el.gegeben - el.eingehalten);
    },
    nichtErledigtLast() {
      return this.$store.state.projectData.kpi.aezWeek
        .map((el) => el.gegeben - el.eingehalten)
        .slice(-1);
    },
    aezListKw() {
      return this.$store.state.projectData.kpi.aezWeek.map((el) => Math.round(el.aez * 100));
    },

    // Störungen Doughnut
    disrColors() {
      return this.$store.state.projectData.kpi.storVerteilung.map((el) => el.color);
    },
    disrValues() {
      return this.$store.state.projectData.kpi.storVerteilung.map((el) => el.n);
    },
    disrValuesLast() {
      return this.$store.state.projectData.kpi.storVerteilungLast.map((el) => el.n);
    },
    disrContext() {
      return this.$store.state.projectData.kpi.storVerteilung.map((el) => el.context);
    },

    // Störungen nach Gewerk
    disrDataset() {
      const storeData = this.$store.state.projectData.kpi.storGewerke;
      return this.mapDisruptions(storeData);
    },

    disrDatasetLast() {
      const storeDataLast = this.$store.state.projectData.kpi.storGewerkeLast;
      return this.mapDisruptions(storeDataLast);
    },

    // Meilensteine kummuliert
    msWeeks() {
      return this.$store.state.projectData.kpi.msRollingTotal.map((el) => el.kw).slice(-12);
    },
    msSoll() {
      return this.$store.state.projectData.kpi.msRollingTotal.map((el) => el.soll).slice(-12);
    },
    msIst() {
      return this.$store.state.projectData.kpi.msRollingTotal.map((el) => el.ist).slice(-12);
    },

    // MS circle
    msTotalSoll() {
      return this.$store.state.projectData.kpi.msRollingTotal.map((el) => el.soll).slice(-1)[0];
    },
    msTotalIst() {
      return this.$store.state.projectData.kpi.msRollingTotal.map((el) => el.ist).slice(-1)[0];
    },
    msLastSoll() {
      return this.$store.state.projectData.kpi.msLastTotal.soll;
    },
    msLastIst() {
      return this.$store.state.projectData.kpi.msLastTotal.ist;
    }
  },

  methods: {
    // used to map layered disruption chart
    mapDisruptions(data) {
      // group by disruption number
      const groupByDisNum = data.reduce((r, a) => {
        r[a.dis_num] = [...(r[a.dis_num] || []), a];
        return r;
      }, {});

      // order by disruption number
      const ordered = Object.keys(groupByDisNum)
        .sort()
        .reduce((obj, key) => {
          obj[key] = groupByDisNum[key];
          return obj;
        }, {});

      // map output
      const output = Object.values(ordered).map((group) => {
        const data = group.map((el) => el.n);
        return {
          label: group[0].context,
          backgroundColor: group[0].color,
          data
        };
      });
      return output;
    }
  }
};
