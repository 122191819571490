<template>
  <div class="horizontal-control horizontal-control__single" :style="horizontalScroll">
    <h2>{{ board.board_name }}</h2>
    <!-- control buttons -->
    <div v-if="perm" class="control-item-box-horizontal">
      <!-- edit -->
      <div @click="edit(board.board_id, board.board_name)">
        <base-icon class="icon icon__hc" icon-name="edit">
          <IconEdit />
        </base-icon>
      </div>
      <!-- delete  -->
      <div v-if="boardIndex" @click="deleteBoard(board.board_id)">
        <base-icon class="icon icon__hc" icon-name="delete" viewBox="0 0 32 32">
          <IconDelete />
        </base-icon>
      </div>
      <!-- minus -->
      <div
        v-if="board.board_width > 2"
        @click="updateWidth(board.board_id, 'reduce', board.board_width - 1)"
      >
        <base-icon class="icon icon__hc" icon-name="minus" viewBox="0 0 32 32">
          <IconMinus />
        </base-icon>
      </div>
      <!-- plus -->
      <div @click="updateWidth(board.board_id, 'increase', board.board_width + 1)">
        <base-icon class="icon icon__hc" icon-name="plus" viewBox="0 0 32 32">
          <IconPlus />
        </base-icon>
      </div>
    </div>
  </div>
</template>

<script>
import IconDelete from '@/components/icons/IconDelete';
import IconEdit from '@/components/icons/IconEdit';
import IconMinus from '@/components/icons/IconMinus';
import IconPlus from '@/components/icons/IconPlus';

export default {
  components: {
    IconDelete,
    IconEdit,
    IconMinus,
    IconPlus
  },
  props: {
    board: {
      type: Object,
      required: true
    },
    boardIndex: {
      type: Number,
      required: true
    },
    horizontalScroll: {
      type: String,
      required: true
    },
    perm: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    edit(id, value) {
      this.$emit('editBoard', { id, value });
    },

    deleteBoard(boardId) {
      this.$emit('deleteBoard', boardId);
    },

    updateWidth(boardId, mode, newValue) {
      this.$emit('updateWidth', { boardId, mode, newValue });
    }
  }
};
</script>
